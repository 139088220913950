import { types, Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree";

export const Document = types.model('Document')
  .props({ 
    id: types.string, 
    name: types.string, 
    url: types.string 
  })

export interface DocumentInstance extends Instance<typeof Document> { }
export interface DocumentSnapshotIn extends SnapshotIn<typeof Document> { }
export interface DocumentSnapshotOut extends SnapshotOut<typeof Document> { }




