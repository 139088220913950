import { observer } from 'mobx-react';
import React, { FC, HTMLAttributes } from 'react';
import ActionButton from './ActionButton';

const AdminButton: FC<HTMLAttributes<HTMLElement> & { color?: string, full?: boolean }> = ({ style, children, color, onClick, full, className, ...others }) => {
  const adminStyle = {
    textTransform: 'uppercase',
    backgroundColor: `${full ? color : 'transparent'}`,
    border: `1px solid ${color ? color : '#f44b48'}`,
    color: `${full ? 'white' : (color ? color : '#f44b48')}`,
    borderRadius: '20px',
    fontSize: '12px',
    width: '167px',
    padding: '13px',
    fontWeight: 'bold',
    ...style
  }
  return (
    <ActionButton className={className} style={adminStyle} onClick={onClick}>
      {children}
    </ActionButton>
  )
}

export default observer(AdminButton);