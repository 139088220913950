import React, { Fragment, useEffect } from 'react';
import { AdminModelInstance } from "../model/AdminModel";
import { observer } from 'mobx-react';
import { useStore } from '../model/AdminStoreProvider';

type Props = {
  userID: string;
  children: React.SFC<{ admin: AdminModelInstance }>;
};

const Admin : React.SFC<Props> = ( props ) => {

  const store = useStore();
  const admin = store.getAdminByID(props.userID);

  useEffect(() => {
    store.loadAdminByID(props.userID);
  })

  return (
    <Fragment>
      {admin && props.children && props.children({ admin })}
    </Fragment>
  )
}

export default observer(Admin);