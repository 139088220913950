import React, { FC, Fragment, HTMLAttributes } from 'react';
import HEADER_IMAGE from '../assets/hydroplantLogoComplete.png';
import LOGO_AREA_COMPLETE_WHITE from '../assets/logoAreaCompleteWhite.png';
import WELCOME_LEARNINGLAB_LOGO from '../assets/welcome-learninglab-logo.png';
import BocconiLogo from './BocconiLogo';
import { Layout } from './Layout';

const HydroplantHeader: FC<HTMLAttributes<HTMLDivElement> & { light?: boolean }> = ({ light }) => (
  <Layout>
    {({ components: { Grid, Image } }) => (
      <Fragment>
        <BocconiLogo />
        {
          light === true ?
            (
              <div style={{
                margin: '33px auto auto'
              }}>
                <Image size="mini" inline src={WELCOME_LEARNINGLAB_LOGO} style={{
                  height: '18px',
                  width: '603px',
                }} />
              </div>
            ) : (
              <Fragment>
                <Image size="mini" inline src={HEADER_IMAGE} style={{ width: '267px', height: '47px' }} />
                <Image size="mini" inline src={LOGO_AREA_COMPLETE_WHITE} style={{ width: '305px', height: '36.59px', maxWidth: 'none' }} />
              </Fragment>
            )}
      </Fragment>

    )}
  </Layout>
)
export default HydroplantHeader;