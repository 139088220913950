import React, { useState } from "react";

const DottedCircleContainer: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { size: string; active?: Boolean }
> = ({ style, size, children, active, onClick }) => {
  const [state, setState] = useState({
    hover: false,
  });
  const { hover } = state;

  const className = `${
    active === true ? "dotted-circle-container-active" : ""
  } ${hover === true ? "dotted-circle-container-hover" : ""}`;

  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        borderColor: "#0d549b",
        borderWidth: "1px",
        padding: "20px",
        marginBottom: "5px",
        ...style,
        width: `${size}`,
        height: `${size}`,
        cursor: "pointer",
        borderStyle: `${hover ? "none" : "dashed"}`,
        borderRadius: `50%`,
        backgroundColor: `${hover ? "#f44b48" : "transparent"}`,
      }}
      onMouseEnter={() => setState({ hover: true })}
      onMouseLeave={() => setState({ hover: false })}
    >
      {children}
    </div>
  );
};
export default DottedCircleContainer;
