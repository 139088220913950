import { getEnv, types, Instance, SnapshotIn, applySnapshot, clone, flow, SnapshotOut } from 'mobx-state-tree';
import { AdminState, AdminStateSnapshotOut } from './AdminState';
import { Game, GameInstance } from './Game';
import { Cluster, ClusterInstance } from './Cluster';
import { SessionInstance } from './Session';
import { HotspotInstance } from './Hotspot';
import { User } from './User';
import { gql, NormalizedCacheObject } from 'apollo-boost';
import AdminQuery from '../../queries/AdminQuery';
import { ApolloClient } from 'apollo-client';

export const AdminModel = types.model('AdminModel')
  .props({
    id: types.string,
    state: types.optional(AdminState, {}),
    loading: false,
    errors: types.array(types.string),
    selectedGame: types.maybeNull(Game),
    selectedCluster: types.maybeNull(Cluster),
    user: types.maybeNull(User),
    image: -1
  })

  .actions(self => ({
    setStateSnapshot(state: AdminStateSnapshotOut) {
      try {
        applySnapshot(self.state, state)
      } catch (error) {
        console.error(error)
      }
    },

    setLoading(value: boolean) {
      self.loading = value;
    },

    setErrors(errors: string[]) {
      self.errors.replace(errors);
    }

  }))

  .views(self => ({
    get client() {
      return getEnv<{ client: ApolloClient<NormalizedCacheObject> }>(self)
        .client
    }
  }))

  .actions(self => ({
    fetch: flow(function* () {
      self.setLoading(true);
      yield self.client
        .query({ query: AdminQuery, variables: {}, fetchPolicy: 'network-only' })
        .then((state) => {
          if (state.errors && state.errors.length) {
            self.setErrors(state.errors.map(err => err.message));
          } else {
            self.setErrors([]);
            self.setStateSnapshot(state.data);
            self.setLoading(false);
          }
        })
        .catch(err => self.setErrors([err.message]))
    })
  }))

  .actions(self => ({
    afterCreate() {
      self.fetch();
      setInterval(() => {
        self.fetch()
      }, 1000)
    },

    addSession(name: string) {
      self.client.mutate({
        mutation: gql`
          mutation CreateSession($sessionID:ID!, name:String!) {
            createSession( sessionID: $sessionID, name:$name ) {
              commandID
              rejectionReason
            }
          }
        `,
        variables: {
          sessionID: self.id,
          name
        }
      })
    },
    deleteSession(sessionID: string) { console.log(sessionID) },

  }))

  .actions(self => ({
    setSelectedGame(game: GameInstance) {
      self.selectedGame = clone(game);
      self.selectedCluster = null;
    },
    setSelectedCluster(cluster: ClusterInstance) {
      self.selectedCluster = clone(cluster);
    },

    showPicture(id: number) {
      self.image = id;
    },

    hidePicture() {
      self.image = -1;
    }
  }))

  .views(self => ({
    getSession(sessionID: string): SessionInstance | null {
      const session = self.state.sessions.find(session => session.id === sessionID);
      return session || null;
    },

  }))

  .views(self => ({
    getHotspot(sessionID: string, hotspotID: string) {
      const session = self.getSession(sessionID);
      if (session) {
        const hotspot = session.hotspots && session.hotspots.find((hotspot: HotspotInstance) => hotspot.id === hotspotID);
        return hotspot || null;
      }
      return null;
    },

    getGamesWithSolution(sessionID: string) {
      const session = self.getSession(sessionID);
      return session ? [...session.games /** aggiungere self.state.solutions */] : []
    }
  }))

export interface AdminModelInstance extends Instance<typeof AdminModel> { }
export interface AdminModelSnapshotIn extends SnapshotIn<typeof AdminModel> { }
export interface AdminModelSnapshotOut extends SnapshotOut<typeof AdminModel> { }