import React, { FC, HTMLAttributes } from 'react';
import { Layout } from './Layout';

const HydroplantToggle: FC<HTMLAttributes<HTMLDivElement> & { checked: boolean, onToggleClick: Function }> = ({ checked, onToggleClick }) => (
  <Layout>
    {({ components: { Radio } }) => (

      <Radio className='customToggle' style={{
        width: '225px',
        height: '21px',
        fontFamily: 'Roboto',
        fontSize: '18px',
        color: '#524e4e',
        fontWeight: 'bold',
      }} toggle
        checked={checked}
        label="SHOW TO TEAMS"
        onClick={() => onToggleClick()} />
    )}
  </Layout>
)

export default HydroplantToggle;