import { gql } from "apollo-boost";

export default gql`
  query AdminQuery {
    sessions {
      id
      name
      date
      isLeaderboardEnabled
      isTourOpen
      leaderboard {
        id
        positions {
          rank
          score
          game {
            id
            team {
              id
              name
            }
          }
        }
      }
      hotspots {
        id
        title
        x
        y
        attributes
        image
        wastes
      }
      teams {
        id
        name
      }
      documents {
        id
        url
        name
      }
      games {
        id
        completed
        teamName
        isTourOpen
        pictures {
          id
          url
        }
        discoveredHotspots {
          id
          x
          y
          title
        }
        clusters {
          id
          name
          unit
          pictures {
            id
            url
          }
        }
        rankedClusters {
          clusterID
          rank
        }
        reportDocuments {
          id
          url
          name
        }
      }
    }
  }
`;
