import React from 'react';
import { useLocalStore } from "mobx-react";
import { ViewModel } from "./ViewModel";
import { ViewModelInstance } from "./ViewModel";
declare global {
  interface Window { store: any; user : () => { id : string } }
}


const storeContext = React.createContext<ViewModelInstance | null>(null);

const createStore = () => ViewModel.create({ 
  user : (window.user && window.user()) || null
})


export const StoreProvider : React.SFC = ({ children }) => {
  const store = useLocalStore(createStore);
  window.store = store;
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>
} 

export const useStore = () => {
  const store = React.useContext(storeContext);
  if (!store) { throw new Error(`useStore must be used within a StoreProvider`) }
  else return store;
}