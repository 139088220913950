import { types, Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree";
import { Session } from "./Session";

export const AdminState = types.model('AdminState')
  .props({
    sessions: types.array(Session)
  })

export interface AdminStateInstance extends Instance<typeof AdminState> { }
export interface AdminStateSnapshotIn extends SnapshotIn<typeof AdminState> { }
export interface AdminStateSnapshotOut extends SnapshotOut<typeof AdminState> { }