import React, { Fragment } from "react";
import { FormattedHTMLMessage } from "react-intl";
import { Route, RouteComponentProps, Switch, Redirect } from "react-router";
import { Link } from "react-router-dom";
import EXIT from "../assets/exit.png";
/* import Game from "../components/Game"; */
import { Layout } from "../components/Layout";
import PhotosSidebar from "../components/PhotosSidebar";
import RouteGamePlantMap from "./RouteGamePlantMap";
import RouteGameVirtualTour from "./RouteGameVirtualTour";
import Game from '../components/Game';

export type RouteGameProps = RouteComponentProps<{ gameID: string }>;

class RouteGame extends React.Component<RouteGameProps> {

  render() {
    return (
      <Layout>
        {({ components: { Page, Icon, Grid, Image } }) => (
          <Fragment>
            <Game gameID={this.props.match.params.gameID}>
              {({ game }) => (
                !game.state.isTourOpen
                  ? <Redirect to={`/participant/games/${this.props.match.params.gameID}/report`} />
                  : null
              )}
            </Game>
            <Switch>
              <Route
                path={`${this.props.match.path}/plant-map`}
                component={RouteGamePlantMap}
              />
              <Route
                path={`${this.props.match.path}/virtual-tour`}
                component={RouteGameVirtualTour}
              />

            </Switch>
            <Page.BottomBar
              style={{
                backgroundColor: "white",
                color: "black",
                position: "absolute",
                width: "100%",
                height: '62px',
                bottom: "0",
                fontSize: '0.75rem'
              }}
            >
              <Grid
                verticalAlign="middle"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  margin: 0,
                  padding: "11px 16px"
                }}
              >
                <Grid.Column
                  width={8}
                  style={{
                    color: "#f44b48",
                    padding: 0
                  }}
                >
                  <Link
                    to={`/participant/games/${this.props.match.params.gameID}/report`}
                    style={{ color: "#f44b48" }}
                  >
                    <Image
                      style={{
                        width: 40,
                        height: 40,
                        display: "inline-block",
                        padding: '6px'
                      }}
                      src={EXIT}
                    />{" "}
                    <FormattedHTMLMessage id="goToReport:text" />
                  </Link>
                </Grid.Column>
                <Grid.Column
                  width={8}
                  floated="right"
                  textAlign="right"
                  style={{ color: "#62c2d0", padding: 0 }}
                >
                  <PhotosSidebar gameID={this.props.match.params.gameID} />
                </Grid.Column>
              </Grid>
            </Page.BottomBar>
          </Fragment>
        )}
      </Layout>
    );
  }
}

export default RouteGame;
