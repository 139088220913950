import { gql } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { flow } from 'mobx';
import { observer } from 'mobx-react';
import { applySnapshot, getPath, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import React, { Fragment } from 'react';
import GameQuery from '../../queries/GameQuery';
import { SyncModel } from '../SyncModel';
import { Cluster, ClusterInstance } from './Cluster';
import { GameState, GameStateSnapshotOut } from './GameState';
import { HotspotInstance } from './Hotspot';

const SyncCluster = SyncModel(Cluster);

const client = new ApolloClient({
  link: createUploadLink({
    uri: window._env_.API_ENDPOINT
  }),
  cache: new InMemoryCache()
})

export const GameModel = types.model('GameModel')
  .props({
    id: types.string,
    state: types.optional(GameState, {}),
    loading: false,
    errors: types.array(types.string),
    syncSelectedCluster: types.maybeNull(SyncCluster),
    syncClusterInEditing: types.maybeNull(SyncCluster),
    sidebar: false,
    image: -1,
    clusterEditMode: types.maybeNull(types.string)
    /* editMode: types.maybeNull(types.enumeration(['name', 'unit'])) */
  })

  .actions(self => ({
    setStateSnapshot(state: GameStateSnapshotOut) {
      try {
        applySnapshot(self.state, state)
      } catch (error) {
        console.error(error)
      }
    },

    setLoading(value: boolean) {
      self.loading = value;
    },

    setErrors(errors: string[]) {
      self.errors.replace(errors);
    }

  }))

  .actions(self => ({
    fetch: flow(function* () {
      self.setLoading(true);
      yield client.query({ query: GameQuery, variables: { gameID: self.id }, fetchPolicy: 'network-only' })
        .then((state) => {
          if (state.errors && state.errors.length) {
            self.setErrors(state.errors.map(err => err.message));
          } else {
            self.setErrors([]);
            self.setStateSnapshot(state.data.game);
            self.setLoading(false);
          }
        })
        .catch(err => self.setErrors([err.message]))
    })
  }))

  .actions(self => ({
    afterCreate() {
      self.fetch();
      setInterval(() => {
        self.fetch()
      }, 500)
    },

    moveToPosition(scene: string) {
      client.mutate({
        mutation: gql`
          mutation GoToScene($gameID:ID!,$scene:String!) {
            gotoScene(
              gameID: $gameID,
              scene: $scene
            ) {
              commandID
              rejectionReason
              rejectionType
            }
          }
        `,
        variables: {
          scene,
          gameID: self.id
        }
      })
    },

    takeAPicture(data: { scene: string, fov: number, h: number, v: number, picture: File }) {
      client.mutate({
        mutation: gql`
          mutation TakeAPicture($gameID:ID!,$picture:Upload!,$h:Float!,$v:Float!,$fov:Float!,$scene:String!) {
            takeAPicture(
              gameID: $gameID,
              picture:{
                picture: $picture,
                angleV:$v,
                angleH: $h,
                fieldOfView:$fov,
                scene:$scene
              }
            ) {
              commandID
              rejectionReason
              rejectionType
            }
          }
        `,
        variables: {
          ...data,
          gameID: self.id
        }
      })
    },

    deletePicture(pictureID: string) {
      client.mutate({
        mutation: gql`
          mutation DeletePicture($gameID:ID!,$pictureID:ID!) {
            deletePicture(
              gameID: $gameID,
              pictureID: $pictureID
            ) {
              commandID
              rejectionReason
              rejectionType
            }
          }
        `,
        variables: {
          pictureID,
          gameID: self.id
        }
      })
    },

    tagPicture(clusterID: string, pictureID: string) {
      client.mutate({
        mutation: gql`
          mutation TagPicture($gameID:ID!,$pictureID:ID!,$clusterID:ID!) {
            tagPicture(
              gameID: $gameID,
              pictureID: $pictureID,
              clusterID: $clusterID
            ) {
              commandID
              rejectionReason
              rejectionType
            }
          }
        `,
        variables: {
          pictureID,
          clusterID,
          gameID: self.id
        }
      })
    },

    untagPicture(pictureID: string) {
      client.mutate({
        mutation: gql`
          mutation UntagPicture($gameID:ID!,$pictureID:ID!) {
            untagPicture(
              gameID: $gameID,
              pictureID: $pictureID
            ) {
              commandID
              rejectionReason
              rejectionType
            }
          }
        `,
        variables: {
          pictureID,
          gameID: self.id
        }
      })
    },

    renameCluster(clusterID: string, name: string) {
      client.mutate({
        mutation: gql`
          mutation RenameCluster($gameID:ID!,$clusterID:ID!,$name: String!) {
            renameCluster(
              gameID: $gameID,
              clusterID: $clusterID,
              name: $name
            ) {
              commandID
              rejectionReason
              rejectionType
            }
          }
        `,
        variables: {
          name,
          clusterID,
          gameID: self.id
        }
      })
    },

    addReportDocument(file: File) {
      client.mutate({
        mutation: gql`
          mutation AddReportDocument($gameID:ID!,$file:Upload!) {
            addReportDocument(
              gameID: $gameID,
              file: $file
            ) {
              commandID
              rejectionReason
              rejectionType
            }
          }
        `,
        variables: {
          file,
          gameID: self.id
        }
      })
    },

    deleteReportDocument(documentID: string) {
      client.mutate({
        mutation: gql`
          mutation DeleteReportDocument($gameID:ID!,$documentID:ID!) {
            deleteReportDocument(
              gameID: $gameID,
              documentID: $documentID
            ) {
              commandID
              rejectionReason
              rejectionType
            }
          }
        `,
        variables: {
          documentID,
          gameID: self.id
        }
      })
    },

    commitCompletion() {
      client.mutate({
        mutation: gql`
          mutation CompleteGame($gameID:ID!) {
            completeGame(
              gameID: $gameID,
            ) {
              commandID
              rejectionReason
              rejectionType
            }
          }
        `,
        variables: {
          gameID: self.id
        }
      })
    },

    setClusterRank(clusterID: string, rank: number) {
      client.mutate({
        mutation: gql`
          mutation RankCluster($gameID:ID!, $clusterID: ID!,$rank: Int!) {
            rankCluster(
              gameID: $gameID,
              clusterID: $clusterID,
              rank: $rank
            ) {
              commandID
              rejectionReason
              rejectionType
            }
          }
        `,
        variables: {
          gameID: self.id,
          clusterID: clusterID,
          rank: rank
        }
      })
    },

    setUnitCluster(clusterID: string, unit: string) {
      client.mutate({
        mutation: gql`
          mutation RenameClusterUnit($gameID:ID!, $clusterID: ID!, $unit: String!) {
            renameClusterUnit(
              gameID: $gameID,
              clusterID: $clusterID,
              unit: $unit
            ) {
              commandID
              rejectionReason
              rejectionType
            }
          }
        `,
        variables: {
          gameID: self.id,
          clusterID: clusterID,
          unit: unit
        }
      })
    }
  }))

  .actions(self => ({
    setSelectedCluster(cluster: ClusterInstance) {
      self.syncSelectedCluster = SyncCluster.create({
        originalStatePath: getPath(cluster),
        autosync: true
      })
    },

    deselectCluster() {
      self.syncSelectedCluster = null;
    },

    setClusterInEditing(cluster: ClusterInstance) {
      const syncCluster = SyncCluster.create({
        originalStatePath: getPath(cluster)
      })
      self.syncClusterInEditing = syncCluster;
    },

    deselectClusterInEditing() {
      self.syncClusterInEditing && self.syncClusterInEditing.sync();
      self.syncClusterInEditing = null;
    },
    editNameCluster() {
      self.clusterEditMode = 'name';
    },
    editUnitCluster() {
      self.clusterEditMode = 'unit';
    },
    exitClusterEditMode() {
      self.clusterEditMode = '';
    }


  }))

  .actions(self => ({
    openSidebar() {
      self.sidebar = true;
    },
    closeSidebar() {
      self.sidebar = false
    },

    showPicture(id: number) {
      self.image = id;
    },

    hidePicture() {
      self.image = -1;
    }
  }))

  .views(self => ({
    getHotspot(hotspotID: string) {
      const hotspot = self.state && self.state.discoveredHotspots.find((hotspot: HotspotInstance) => hotspot.id === hotspotID);
      return hotspot || null;
    },

    get selectedCluster() {
      return self.syncSelectedCluster && self.syncSelectedCluster.state
    },

    get clusterInEditing() {
      return self.syncClusterInEditing && self.syncClusterInEditing.state
    }
  }))

  .views(self => ({
    ClusterName(cluster: { id: string, name: string }) {
      return observer(() => (
        <Fragment>
          <pre>{cluster.name}</pre>
        </Fragment>
      ))
    },
  }))

export interface GameModelInstance extends Instance<typeof GameModel> { }
export interface GameModelSnapshotIn extends SnapshotIn<typeof GameModel> { }
export interface GameModelSnapshotOut extends SnapshotOut<typeof GameModel> { }