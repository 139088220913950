import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { Link } from "react-router-dom";
import START_ARROW from "../assets/start_arrow.png";
import ActionButton from "../components/ActionButton";
import Game from "../components/Game";
import { Layout } from "../components/Layout";
import OnboardingCard from '../components/OnboardingCard';
import WelcomeTeamName from '../components/WelcomeTeamName';
import { RouteGameProps } from "./RouteGame";


class RouteGameOnboarding extends React.Component<RouteGameProps> {
  render() {
    return (
      <Layout>
        {({
          components: {
            Header,
            Card,
            Icon,
            Segment,
            Grid,
            Image
          }
        }) => (
            <Game gameID={this.props.match.params.gameID}>
              {({ game }) => (
                <div
                  style={{
                    width: "100%",
                    height: "calc(100vh - 100px)",
                    background: 'linear-gradient( white, #e8f9fb 51%)',
                    boxShadow: "inset 0 0 0 1000px rgba(232, 249, 251, 0.91)",
                  }}
                >
                  <div
                    style={{
                      width: "80%",
                      margin: 'auto'
                    }}
                  >

                    <Segment
                      textAlign="right"
                      basic
                      style={{
                        padding: 0,
                        margin: 0,
                        marginBottom: "35px",
                      }}
                    >
                      <WelcomeTeamName>{game.state.team.name}</WelcomeTeamName>
                    </Segment>
                    <OnboardingCard />
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        width: "100%",
                        margin: "auto",
                        marginTop: "3rem"
                      }}
                    >
                      <a href={game.state.caseUrl} download>
                        <ActionButton>
                          <Icon name="cloud download" />
                          <FormattedHTMLMessage id="download.case:button" />
                        </ActionButton>
                      </a>
                      <Link
                        to={`/participant/games/${this.props.match.params.gameID}/virtual-tour`}
                      >
                        <ActionButton>
                          <FormattedHTMLMessage id="start:button" />
                          <Image src={START_ARROW} style={{
                            display: 'inline-block', width: 26, height: 16, marginLeft: 10, verticalAlign: 'baseline'
                          }} />
                        </ActionButton>
                      </Link>
                    </Grid>
                  </div>
                </div>
              )}
            </Game>
          )}
      </Layout>
    );
  }
}

export default RouteGameOnboarding;
