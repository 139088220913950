import React, { Fragment } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { RouteComponentProps } from "react-router";
import AddSession from '../components/AddSession';
import Admin from '../components/Admin';
import AdminSectionHeader from '../components/AdminSectionHeader';
import SessionsList from '../components/SessionsList';

export type RouteSessionsProps = RouteComponentProps<{ userID: string }>;

class RouteSessions extends React.Component<RouteSessionsProps> {

  render() {
    return (
      <Admin userID={this.props.match.params.userID}>
        {({ admin }) => (
          <Fragment>
            <AdminSectionHeader>
              <FormattedHTMLMessage id="admin:welcome" />,
              <span color='#f44b48'>{admin.user && admin.user.name}</span>
            </AdminSectionHeader>
            <AddSession onAdd={(data: any) => admin.addSession(data)} />
            <SessionsList
              userID={this.props.match.params.userID}
              sessions={admin.state.sessions}
            />
          </Fragment>
        )}
      </Admin>
    )

  }
}

export default RouteSessions;