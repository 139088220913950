import React, { FC } from 'react';
import DOWNLOAD_ICON from '../assets/docLink.png';
import TRASH_ICON from '../assets/trash.svg';
import { DocumentInstance } from '../model/GameModel/Document';
import DocumentsList from './DocumentsList';
import { Layout } from './Layout';

type EditableDocListProps = { documents: DocumentInstance[], edit: boolean, onDelete?: (documentID: string) => void }

const EditableDocList: FC<EditableDocListProps> = ({ documents, edit = true, onDelete = () => { } }) => {

  const getDeletableIcon = (docID: string, Image: any) => {
    return <Image src={TRASH_ICON} onClick={() => onDelete(docID)} style={{ width: '20px', cursor: 'pointer' }} />
  }

  const getDownloadIcon = (url: string, Image: any) => {
    return <a href={url} download><Image src={DOWNLOAD_ICON} size='small' style={{ width: '20px', cursor: 'pointer' }} /></a>
  }

  const getDocuments = (Image: any) => (
    documents.map(doc => ({
      ...doc,
      icon: !edit ? getDownloadIcon(doc.url, Image) : getDeletableIcon(doc.id, Image)
    }))
  )

  return (
    <Layout>
      {({ components: { Image } }) => (
        <DocumentsList documents={getDocuments(Image)} />
      )}
    </Layout>
  )


}

export default EditableDocList;