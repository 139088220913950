import { types, Instance, SnapshotIn } from 'mobx-state-tree';
import { GameModel } from './GameModel/';


const User = types.model('User')
  .props({
    id : ''
  })

export const ViewModel = types.model('ViewModel')
  .props({
    user : types.maybeNull(User),
    games : types.map(GameModel)
  })

  .views(self=>({
    getGameByID( id : string ) {
      return self.games.get( id );
    }
  }))

  .actions(self=>({
    loadGameByID( id : string ) {
      if ( !self.games.has(id) ) {
        self.games.set(id, { id })
      }
    }
  }))

export interface ViewModelInstance extends Instance<typeof ViewModel> { }
export interface ViewModelSnapshotIn extends SnapshotIn<typeof ViewModel> { }