import React from "react";
import { InferProps } from "prop-types";
import MAP from "../assets/map.png";
import { GameModelInstance } from "../model/GameModel";
import { HotspotInstance } from "../model/GameModel/Hotspot";
import CurrentPositionIcon from "./CurrentPositionIcon";
import Hotspot from "./Hotspot";
import PlantMap from "./PlantMap";

const propTypes = {};

type Props = InferProps<typeof propTypes> & {
  game: GameModelInstance;
  location: string;
};

const UserHydroplantMap: React.FC<Props> = ({ game, location }) => {
  const getDetailUrl = (hotspot: HotspotInstance) => {
    return {
      ...hotspot,
      detailUrl: `${location}/hotspots/${hotspot.id}`,
    };
  };

  const getUncompletedGameSeries = () => {
    return [
      {
        id: "currentPosition",
        data: [
          {
            id: "current-position",
            title: "current-position:label",
            ...game.state.position,
          },
        ],
        options: {
          icon: <CurrentPositionIcon />,
        },
      },
      {
        id: "discoveredHotspot",
        data: game.state.discoveredHotspots.map(getDetailUrl),
        options: {
          icon: <Hotspot levels={2} color="#f44b48" width={30} />,
          selectedIcon: <Hotspot levels={2} color="gray" width={30} />,
        },
      },
    ];
  };

  const getCompletedGameSeries = () => {
    return [
      {
        id: "discoveredHotspot",
        data: game.state.discoveredHotspots.map(getDetailUrl),
        options: {
          icon: <Hotspot levels={2} color="#f44b48" width={30} />,
          selectedIcon: <Hotspot levels={2} color="gray" width={30} />,
        },
      },
    ];
  };

  return (
    <PlantMap
      series={
        game.state.completed
          ? getCompletedGameSeries()
          : getUncompletedGameSeries()
      }
      mapUrl={MAP}
      showLegend
    />
  );
};

export default UserHydroplantMap;
