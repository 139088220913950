import { types, Instance, SnapshotIn } from 'mobx-state-tree';
import { AdminModel } from './AdminModel/';

export const AdminViewModel = types.model('AdminViewModel')
  .props({
    admins: types.map(AdminModel)
  })

  .views(self => ({
    getAdminByID(id: string) {
      return self.admins.get(id);
    }
  }))

  .actions(self => ({
    loadAdminByID(id: string) {
      if (!self.admins.has(id)) {
        self.admins.set(id, { id })
      }
    }
  }))

export interface AdminViewModelInstance extends Instance<typeof AdminViewModel> { }
export interface AdminViewModelSnapshotIn extends SnapshotIn<typeof AdminViewModel> { }