import { types, Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree";

export const Hotspot = types.model("Hotspot").props({
  id: types.string,
  title: types.string,
  image: types.maybeNull(types.string),
  attributes: types.array(
    types.model({
      id: types.string,
      name: types.string,
      kind: types.string,
      value: types.string,
    })
  ),
  wastes: types.array(
    types.model({
      wastePhase: types.number,
      projectAnalysisTimeWaste: types.string,
      materAndCompWithdrawalTimeWaste: types.number,
      waitingAndQueuingTimeWaste: types.number,
      withdrawalMistakesAndQualityTimeWaste: types.number,
    })
  ),
  x: types.number,
  y: types.number,
});

export interface HotspotInstance extends Instance<typeof Hotspot> {}
export interface HotspotSnapshotIn extends SnapshotIn<typeof Hotspot> {}
export interface HotspotSnapshotOut extends SnapshotOut<typeof Hotspot> {}
