import React from 'react';
import { Segment, StrictSegmentProps } from 'semantic-ui-react';

type Props = {
  textAlign?: StrictSegmentProps['textAlign']
}

export type InlineProps = React.HTMLAttributes<HTMLElement> & Props;

export const Inline: React.FunctionComponent<InlineProps> = ({ children, style, textAlign }) => (
  <Segment basic textAlign={textAlign ? textAlign : 'center'} style={style} >
    <Segment.Inline>
      {children}
    </Segment.Inline>
  </Segment>
)
