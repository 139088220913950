import React, { Fragment } from 'react';
import { number, InferProps } from 'prop-types';

const propTypes = {
  selected: number.isRequired,
  size: number.isRequired
}

export type SlideshowProps = InferProps<typeof propTypes>;
export type SlideshowType = typeof Slideshow;

const Item: React.SFC<React.HTMLAttributes<HTMLElement>> = ({ children }) => {
  return <Fragment>{children}</Fragment>
}

export default class Slideshow extends React.Component<SlideshowProps> {

  public static Item = Item

  printChildren() {
    const { children, selected, size } = this.props;
    return children && Array.isArray(children) ?
      children.slice(selected, size + selected) :
      null;
  }

  render() {
    return (
      <Fragment>{this.printChildren()}</Fragment>
    )
  }
}
