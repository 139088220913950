import React from 'react';
import { InferProps, string } from 'prop-types';

const propTypes = {
  src: string,
}

export type PictureProps = InferProps<typeof propTypes>;
export type PictureType = typeof Picture;

export const Picture: React.SFC<PictureProps & React.HTMLAttributes<HTMLElement>> = ({ src, style, onClick }) => (
  <img src={src || ""} alt="" style={style} onClick={onClick} />
)