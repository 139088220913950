import React from "react";
import ArrowController from "./ArrowController";
import CloseIcon from './CloseIcon';
import { Layout } from "./Layout";
import Overlay from "./Overlay";
import Slideshow from "./Slideshow";
import { PictureInstance } from "../model/AdminModel/Picture";
import { observer } from "mobx-react";

type OverlayGalleryProps = {
  pictures: PictureInstance[],
  selectedImage: number,
  onChange: (index: number) => void,
  onClose: () => void
}

class OverlayGallery extends React.Component<OverlayGalleryProps> {

  constructor(props: OverlayGalleryProps) {
    super(props);
    this.manageKeyEvent = this.manageKeyEvent.bind(this);
  }


  manageKeyEvent(event: any) {
    const { pictures, selectedImage, onChange, onClose } = this.props;
    switch (event.keyCode) {
      case 37:
      case 39:
        const max = pictures.length;
        const rawValue = event.keyCode === 37 ? selectedImage - 1 : selectedImage + 1;
        const value = rawValue < 0 ? max - 1 : rawValue % max;

        onChange(value)
        break;
      case 27:
        onClose();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.manageKeyEvent);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.manageKeyEvent);
  }

  render() {
    const { pictures, selectedImage, onClose, onChange } = this.props
    return (
      <Layout>
        {({ components: { Image } }) => (
          <Overlay>
            <CloseIcon
              onClick={() => { onClose() }}
            />
            <ArrowController
              white
              max={pictures.length}
              value={selectedImage}
              onChange={value => {
                onChange(value)
              }}
            >
              {({ selected }) => (
                <Slideshow size={1} selected={selected}>
                  {pictures.map((image: any, index: number) => (
                    <Slideshow.Item key={index}>
                      <Image
                        src={image.url}
                        style={{
                          position: 'absolute',
                          right: 0,
                          left: 0,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          margin: '0 auto',
                          width: '80%'
                        }}
                      />
                    </Slideshow.Item>
                  ))}
                </Slideshow>
              )}
            </ArrowController>
          </Overlay>
        )}
      </Layout>
    )
  }

}

export default observer(OverlayGallery)