import React, { FC, HTMLAttributes } from 'react';
import EDIT from '../assets/edit.png';
import { Layout } from './Layout';

const EditIcon: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...others }) => (
  <Layout >
    {({ components: { Image } }) => (
      <Image src={EDIT} style={{
        color: '#f44b48', width: '18px', height: '18px', cursor: 'pointer'
      }} />
    )}
  </Layout>

)

export default EditIcon;