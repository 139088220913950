import { observer } from 'mobx-react';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { ClusterInstance } from '../model/GameModel/Cluster';
import DottedCircleContainer from './DottedCircleContainer';
import { Layout } from './Layout';
import PicturesStack from './PicturesStack';
import NameClusterInput from './NameClusterInput';
import UnitClusterInput from './UnitClusterInput';

const ClusterPicturesLength: React.FC<{ value: number }> = ({ value }) => (
  <Layout>
    {({ components: { Paragraph } }) => (
      <div style={{ fontWeight: "bold", color: "#0d549b" }}>
        <Paragraph>
          {value} <small><FormattedHTMLMessage id="photos.added" defaultMessage="photos added" /></small>
        </Paragraph>
      </div>
    )}
  </Layout>
)

type Props = {
  cluster: ClusterInstance,
  clusterInEditing: ClusterInstance | null,
  onClusterNameChange: (name: string) => void,
  onUnitChange: (unit: string) => void,
  onNameEditSelection: () => void
  onUnitEditSelection: () => void
  onClick: () => void,
  nameEdit: boolean
  unitEdit: boolean
}

const GameClusters: React.FC<Props> = ({ cluster, clusterInEditing, onClusterNameChange, onNameEditSelection, onClick, nameEdit, unitEdit, onUnitChange, onUnitEditSelection }) => {
  return (
    <div className='GameClusterContainer'>
      <NameClusterInput
        edit={nameEdit}
        value={clusterInEditing ? clusterInEditing.name : cluster.name}
        onValueChange={clusterInEditing ? clusterInEditing.setName : cluster.setName}
        onEnterEdit={onNameEditSelection}
        onSave={onClusterNameChange}
      />
      <UnitClusterInput
        edit={unitEdit}
        value={clusterInEditing ? clusterInEditing.unit : cluster.unit}
        onValueChange={clusterInEditing ? clusterInEditing.setUnit : cluster.setUnit}
        onEnterEdit={onUnitEditSelection}
        onSave={onUnitChange}
      />
      <DottedCircleContainer size={'114px'} onClick={onClick}>
        <PicturesStack
          pictures={cluster.pictures}
          size={2}
          borderSize={10}
          anglesArray={[-20, 3]}
          style={{ cursor: 'pointer' }}
        />
      </DottedCircleContainer>
      <ClusterPicturesLength value={cluster.pictures.length} />
    </div>
  )
}

export default observer(GameClusters);