import React, { Fragment } from "react";
import {
  Button,
  Card,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Modal,
  Segment,
  Sidebar,
  Table,
  Radio,
  Select,
  Menu,
  Input
} from "semantic-ui-react";
import { DownloadType } from "../layout/Download";
import { Page } from "../layout/Page";
import { PictureType } from "../layout/Picture";
import { TextInput } from "../layout/TextInput";
import Container from "../layout/Container";
import { Inline } from '../layout/Inline';
import { observer } from "mobx-react";

type ExtractProps<
  TComponentsOrProps
  > = TComponentsOrProps extends React.ComponentType<infer TProps>
  ? TProps
  : TComponentsOrProps;

type ExtractContextProps<T> = {
  [P in keyof T]: ExtractProps<T[P]>;
};

export type LayoutContext = {
  Page: typeof Page;
  Button: typeof Button;
  Radio: typeof Radio;
  Segment: typeof Segment;
  Header: typeof Header;
  Paragraph: React.SFC;
  TextInput: typeof TextInput;
  Modal: typeof Modal;
  Card: typeof Card;
  Picture: PictureType;
  Icon: typeof Icon;
  Image: typeof Image;
  Inline: typeof Inline;
  Download: DownloadType;
  Divider: React.SFC;
  Grid: typeof Grid;
  Sidebar: typeof Sidebar;
  Dropdown: typeof Dropdown;
  Table: typeof Table;
  Select: typeof Select;
  Menu: typeof Menu;
  Container: typeof Container;
  Input: typeof Input;
};

export type LayoutContextProps = ExtractContextProps<LayoutContext>;

const layoutContext = React.createContext<LayoutContext | null>(null);

export const LayoutProvider: React.SFC<{ context: LayoutContext }> = ({
  context,
  children
}) => {
  return (
    <layoutContext.Provider value={context}>{children}</layoutContext.Provider>
  );
};

export const useLayout = () => {
  const layout = React.useContext(layoutContext);
  if (!layout) {
    throw new Error(`useLayout must be used within a LayoutProvider`);
  } else return layout;
};

export const Layout: React.SFC<{
  children: React.SFC<{ components: LayoutContext }>;
}> = observer(({ children }) => {
  const components = useLayout();

  return <Fragment>{children({ components })}</Fragment>;
});