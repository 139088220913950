import { InferProps } from "prop-types";
import React from "react";

const propTypes = {
  style: Object
};

export type ParagraphProps = InferProps<typeof propTypes>;

export const Paragraph: React.FunctionComponent<ParagraphProps> = ({
  children,
  style
}) => <p>{children}</p>;
