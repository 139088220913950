import React, { FC } from 'react';
import { Layout } from './Layout';
import { FormattedHTMLMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import RankClusterDropdown from './RankClusterDropdown';
import range from 'lodash/range';

type Props = {
  clusters: Array<any>
  onSelectCluster: Function
  rankedClusters: Array<string>
  disabled?: boolean
} & WrappedComponentProps

const ClustersRankingSection: FC<Props> = ({ clusters, onSelectCluster, intl, rankedClusters, disabled=false }) => {
  return (
    <Layout>
      {({ components: { Grid } }) => (
        <Grid verticalAlign="middle">
          <Grid.Column width={4}>
            {
              disabled
                ? <FormattedHTMLMessage id='cluster-ranking-section:description.completed' />
                : <FormattedHTMLMessage id='cluster-ranking-section:description' />
            }
          </Grid.Column>
          {
            range(1, 4).map(rank => (
              <Grid.Column width={4} key={rank}>
                <RankClusterDropdown
                  disabled={disabled}
                  clusters={clusters}
                  onChange={(cluster) => onSelectCluster(cluster, rank)}
                  value={rankedClusters[rank - 1]}
                  placeholder={intl.formatHTMLMessage({ id: `cluster-ranking-section:placeholder${rank}` })} />
              </Grid.Column>
            ))
          }
        </Grid>
      )}
    </Layout>
  )
}

export default injectIntl(ClustersRankingSection);