import React, { FC, HTMLAttributes, RefObject, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Layout } from './Layout';
import ADD_SESSION_IMG from '../assets/addSession.png'

const Style: FC<HTMLAttributes<HTMLDivElement> & { innerRef?: RefObject<HTMLDivElement> }> = ({ children, innerRef, ...others }) => (
  <Layout>
    {({ components: { Image, Inline } }) => (
      <div {...others} style={{
        backgroundColor: 'white',
        border: 'solid 1px rgba(0, 0, 0, 0.15)', borderRadius: '2px',
        cursor: 'pointer',
        height: '48px', width: '48px',
        verticalAlign: 'middle',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Image src={ADD_SESSION_IMG} style={{ display: 'inline-block', width: '18px', height: '22px' }} />
        {children}
      </div>
    )}
  </Layout >
)

const CreateSessionUploader: FC<HTMLAttributes<HTMLDivElement> & { onUpload?: Function }> = ({ children, onUpload, ...others }) => {

  const onDrop = useCallback(async acceptedFiles => {
    /* if (endpoint) {
      const form = new FormData();
      form.append('file', acceptedFiles);
      const res = await fetch(`${endpoint}`, {
        method: 'POST',
        body: form
      });
      onUpload(res);
    } */
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  return (
    <Style {...getRootProps({ refKey: 'innerRef' })}>
      <input {...getInputProps()} />
    </Style>
  )
}

export default CreateSessionUploader;



