import React, { FC, HTMLAttributes } from 'react';
import { TextInput } from '../layout/TextInput';

type Props = {
  edit: boolean
  onValueChange: any
  onEnterEdit: Function
  onSave: Function
  value: string
} & HTMLAttributes<HTMLInputElement>

const UnitClusterInput: FC<Props> = ({ edit, value, onValueChange, onEnterEdit, onSave }) => (
  <TextInput
    className='UnitClusterInput'
    edit={edit}
    value={value}
    placeholder="unit"
    onChange={onValueChange}
    onEditIconClick={() => {
      onEnterEdit()
    }}
    onSaveIconClick={(value: string) => {
      onSave(value)
    }}
    style={{
      height: "25px",
      placeItems: "center",
      width: "100%",
      color: "#524e4e"
    }}
  />
)
export default UnitClusterInput;