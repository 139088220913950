import React from 'react';
import papers from '../assets/papers.png';
import CloseIcon from './CloseIcon';

const PaperTablet: React.SFC<{ onClose: Function }> = ({ children, onClose }) => (
  <div style={{
    position: 'absolute',
    backgroundImage: `url(${papers})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    top: 0,
    bottom: 0,
    width: '700px',
    margin: 'auto',
    backgroundSize: 'contain',
    left: '50%',
    transform: 'translate(-50%)'
  }}>
    <CloseIcon
      color='red'
      style={{ position: 'absolute', right: '7rem', top: '6rem' }}
      onClick={() => { onClose() }} />
    {children}
  </div>
)

export default PaperTablet;