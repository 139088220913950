import range from 'lodash/range';
import { bool, func, InferProps, number } from 'prop-types';
import React, { FC, Fragment, HTMLAttributes } from 'react';
import { Pagination } from 'semantic-ui-react';
import { Layout } from './Layout';

const propTypes = {
  selectedPage: number.isRequired,
  pagesNumber: number.isRequired,
  onPageClick: func.isRequired,
  onLeftLimitClick: func.isRequired,
  onRightLimitClick: func.isRequired,
  asMenu: bool
}

type Props = InferProps<typeof propTypes>

const TablePaginator: FC<HTMLAttributes<HTMLDivElement> & Props> = ({ style, selectedPage: selectedPageProp, pagesNumber, onPageClick, onLeftLimitClick, onRightLimitClick, asMenu }) => {

  const selectedPage = selectedPageProp;
  return (
    <Layout>
      {({ components: { Menu, Icon } }) => (
        <Fragment>
          {
            asMenu ? (
              <Menu style={style} pagination ellipsisItem>
                <Menu.Item icon onClick={onLeftLimitClick}>
                  <Icon name='chevron left' />
                </Menu.Item>
                {
                  range(1, pagesNumber + 1).map(el => (
                    <Menu.Item
                      onClick={() => onPageClick(el)}
                      active={el === selectedPage ? true : false}
                    >
                      {el}
                    </Menu.Item>
                  ))
                }
                <Menu.Item icon onClick={onRightLimitClick}>
                  <Icon name='chevron right' />
                </Menu.Item>
              </Menu>
            ) : (
                <Pagination
                  totalPages={pagesNumber}
                  activePage={selectedPage}
                  onPageChange={(event, data) => { onPageClick(data.activePage) }}
                  siblingRange={1}
                  boundaryRange={1}
                  nextItem={{
                    content: 'next',
                    onClick: () => onPageClick(selectedPage)
                  }}
                  prevItem={{
                    content: 'prev',
                    onClick: () => onPageClick(selectedPage)
                  }}
                  secondary
                  style={{
                    color: '#524e4e',
                    fontSize: '11px',
                    textAlign: 'center',
                  }}
                />
              )
          }
        </Fragment>

      )}
    </Layout>
  )
}

export default TablePaginator;
