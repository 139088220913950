import { observer } from "mobx-react";
import { InferProps } from "prop-types";
import React, { HTMLAttributes } from "react";

const propTypes = {
  style: Object,
};

type Props = InferProps<typeof propTypes>;

const Item: React.SFC = ({ children }) => (
  <div
    style={{
      display: "inline-flex",
      width: "150px",
      height: "180px",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      margin: "1rem .5rem",
    }}
  >
    {children}
  </div>
);

class PhotoCluster extends React.Component<
  HTMLAttributes<HTMLDivElement> & Props
> {
  static Item = Item;

  render() {
    const { style } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          flexDirection: "row",
          textAlign: "center",
          height: "100%",
          alignContent: "space-between",
          ...style,
          /*           margin: '2.5rem 0'
           */
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default observer(PhotoCluster);
