import React, { FC, HTMLAttributes, RefObject, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import UPLOAD_ICON from '../assets/upload.svg';
import { Layout } from './Layout';


const Style: FC<HTMLAttributes<HTMLDivElement> & { innerRef?: RefObject<HTMLDivElement> }> = ({ children, innerRef, ...others }) => (
  <Layout>
    {({ components: { Image, Inline } }) => (
      <div {...others} style={{
        backgroundColor: '#eaeaea', border: '1px dashed #0d549b', borderRadius: '14px', cursor: 'pointer', height: '5.2rem',
        verticalAlign: 'middle',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Inline style={{}}>
          <Image src={UPLOAD_ICON} style={{ display: 'inline-block', width: '25px', height: '20px', marginRight: '1rem' }} />
          <FormattedMessage id='uploader.standby:label' />
          {children}
        </Inline>
      </div>
    )}
  </Layout>
)

const HydroUploader: FC<HTMLAttributes<HTMLDivElement> & { onUpload: Function }> = ({ children, onUpload, ...others }) => {

  const onDrop = useCallback(async acceptedFiles => {
    onUpload(acceptedFiles[0])
  }, [onUpload])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  return (
    <Style {...getRootProps({ refKey: 'innerRef' })}>
      <input {...getInputProps()} />
    </Style>
  )
}

export default HydroUploader;



