import React, { FC } from 'react';
import { Layout } from './Layout';
import CURRENT_POSITION from '../assets/currentPointer.png';

const CurrentPositionIcon: FC = () => (
  <Layout >
    {({ components: { Image } }) => (
      <Image src={CURRENT_POSITION} style={{ width: '30px', height: '30px' }} />

    )}
  </Layout>
)

export default CurrentPositionIcon;