import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { useLocalStore } from "mobx-react";
import React from 'react';
import { AdminViewModel, AdminViewModelInstance } from "./AdminViewModel";

declare global {
  interface Window { API_ENDPOINT: string; }
}

const client = new ApolloClient({
  link: createUploadLink({
    uri: window._env_.API_ENDPOINT
  }),
  cache: new InMemoryCache()
})

const storeContext = React.createContext<AdminViewModelInstance | null>(null);

const createStore = () => AdminViewModel.create({}, {
  client
});

export const StoreProvider: React.SFC = ({ children }) => {
  const store = useLocalStore(createStore);
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export const useStore = () => {
  const store = React.useContext(storeContext);
  if (!store) { throw new Error(`useStore must be used within a StoreProvider`) }
  else return store;
}