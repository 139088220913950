import { observer } from 'mobx-react';
import React from 'react';
import Game from './Game';
import GameCluster from './GameCluster';
import GameClusterPictures from './GameClusterPictures';
import PhotoCluster from './PhotoCluster';

const GamePhotoClusters: React.FC<{ gameID: string, edit?: boolean }> = ({ gameID, edit = true }) => {

  return (
    <Game gameID={gameID}>
      {({ game }) => {
        if (game.selectedCluster) {
          return <GameClusterPictures cluster={game.selectedCluster}
            onSelect={() => { game.deselectCluster() }}
            onUntag={(pictureID) => {
              game.untagPicture(pictureID)
            }
            } />
        } else {
          return (
            <PhotoCluster style={{ paddingTop: '2.5rem' }}>
              {
                game.state.clusters.map((cluster: any, index: number) => (
                  <PhotoCluster.Item key={index}>
                    <GameCluster cluster={cluster}
                      clusterInEditing={game.clusterInEditing && game.clusterInEditing.id === cluster.id ? game.clusterInEditing : null}
                      nameEdit={!!(game.clusterEditMode === 'name' && game.clusterInEditing && game.clusterInEditing.id === cluster.id)}
                      unitEdit={!!(game.clusterEditMode === 'unit' && game.clusterInEditing && game.clusterInEditing.id === cluster.id)}
                      onClusterNameChange={(name: string) => {
                        game.renameCluster(cluster.id, name);
                        game.deselectClusterInEditing();
                        game.exitClusterEditMode();

                      }}
                      onNameEditSelection={() => {
                        game.setClusterInEditing(cluster);
                        game.editNameCluster();
                      }}
                      onUnitEditSelection={() => {
                        game.setClusterInEditing(cluster);
                        game.editUnitCluster();
                      }}
                      onClick={() => {
                        game.setSelectedCluster(cluster)
                      }}
                      onUnitChange={(unit) => {
                        game.setUnitCluster(cluster.id, unit);
                        game.deselectClusterInEditing();
                        game.exitClusterEditMode();
                      }}
                    />
                  </PhotoCluster.Item>
                ))
              }
            </PhotoCluster>
          )
        }
      }}
    </Game>
  )
}



export default observer(GamePhotoClusters);