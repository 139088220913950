import React, { Fragment } from "react";
import { FormattedHTMLMessage } from "react-intl";
import PHOTOS from "../assets/photos.png";
import Game from "./Game";
import { Layout } from "./Layout";
import OverlayGallery from "./OverlayGallery";

type PhotosSidebarProps = {
  gameID: string
}

const PhotosSidebar: React.FC<PhotosSidebarProps> = ({ gameID }) => {



  return (
    <Game gameID={gameID}>
      {({ game }) => (
        <Fragment>
          <Layout>
            {({ components: { Image } }) => (
              <div style={{
                cursor: 'pointer',
                display: "inline-block",
                color: "#62c2d0",
                marginRight: "0.5rem"
              }} onClick={() => game.openSidebar()}>
                <div className='FooterStackPhotoContainer'
                  style={{
                    position: 'relative',
                    display: 'inline-block'
                  }}>
                  <Image
                    style={{
                      width: 50,
                      height: 40,
                      display: "inline-block",
                    }}
                    src={PHOTOS}
                  />
                  <p style={{
                    position: "absolute", top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}> {game.state.pictures.length}</p>
                </div>

                <span style={{ marginLeft: '.5rem' }}><FormattedHTMLMessage id="photos:title" /></span>
              </div>
            )}
          </Layout>
          {
            game.image !== -1 && (
              <OverlayGallery pictures={game.state.pictures}
                selectedImage={game.image}
                onClose={() => {
                  game.openSidebar();
                  game.hidePicture()
                }}
                onChange={(value: number) => {
                  game.showPicture(value)
                }}
              />
            )
          }
        </Fragment>
      )}
    </Game>
  )
}

export default PhotosSidebar