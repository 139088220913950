import { types, Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree";

export const Picture = types.model('Picture')
  .props({
    id: types.string,
    url: types.string
  })

export interface PictureInstance extends Instance<typeof Picture> { }
export interface PictureSnapshotIn extends SnapshotIn<typeof Picture> { }
export interface PictureSnapshotOut extends SnapshotOut<typeof Picture> { }