import React, { FC } from 'react';
import LOGO_AREA_COMPLETE from '../assets/logoAreaComplete.png';
import { Layout } from './Layout';

const BocconiAreaLogoComplete: FC<{ colored?: boolean }> = ({ colored }) => (
  <Layout>
    {({ components: { Image } }) => (
      <Image
        size="mini"
        inline
        src={LOGO_AREA_COMPLETE}
        style={{ width: "341px", height: '79px' }}
      />
    )}
  </Layout >
)

export default BocconiAreaLogoComplete;