import { observer } from "mobx-react";
import React, { Fragment, useEffect } from "react";
import { GameModelInstance } from "../model/GameModel";
import { useStore } from "../model/StoreProvider";

type Props = {
  gameID: string;
  children: React.SFC<{ game: GameModelInstance }>;
};

const GameTest: React.SFC<Props> = props => {
  const store = useStore();
  const game = store.getGameByID(props.gameID);

  useEffect(() => {
    store.loadGameByID(props.gameID);
  });

  if (game && game.errors.length) {
    return (
      <div>
        Error! Game not found!!
        <pre>{JSON.stringify(game.errors, null, 2)}</pre>
      </div>
    )
  }

  if (game && game.loading && !game.state) {
    return <div>Loading.........</div>
  }

  return (
    <Fragment>{game && props.children && props.children({ game })}</Fragment>
  );
};

export default observer(GameTest);
