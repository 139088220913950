import { arrayOf, InferProps, node, shape, string } from "prop-types";
import React, { Fragment } from "react";

const propTypes = {
  documents: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
    icon: node
  }).isRequired).isRequired
};

export type DocumentsListProps = InferProps<typeof propTypes>

const DocumentRow: React.FC<React.HTMLAttributes<HTMLDivElement> & { icon: any }> = ({ children, icon }) => (
  <div style={{
    backgroundColor: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#0d549b',
    padding: '1.625rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    borderBottom: '1px solid rgba(164, 164, 164, 0.24)',
    alignItems: 'center'
  }}>
    <p style={{ margin: '0', lineHeight: '2' }}>{children}</p> {icon}
  </div >
)

const DocumentsList: React.FC<DocumentsListProps> = ({
  children,
  documents,
  ...others
}) => (
    <Fragment>
      {
        documents.map((doc, index) => (
          <DocumentRow
            key={index}
            icon={doc.icon}
          >{doc.name}</DocumentRow>
        ))
      }
    </Fragment>
  );

export default DocumentsList;