import range from 'lodash/range';
import React, { FC, HTMLAttributes } from "react";
import { FormattedHTMLMessage } from "react-intl";
import { Route, RouteComponentProps, Switch } from "react-router";
import CHECKED_ICON from '../assets/checked.png';
import AdminButton from '../components/AdminButton';
import { Layout } from "../components/Layout";

const CustomRadio: FC<HTMLAttributes<HTMLDivElement> & { checked?: boolean, size?: number }> = ({ checked, size, ...others }) => (
  <div style={{
    width: `${size ? `${size}px` : '20px'}`, height: `${size ? `${size}px` : '20px'}`,
    backgroundColor: `${checked ? '#f44b48' : '#f8f8f8'}`,
    border: 'solid 3px #e0e0e0',
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: '0.28571429rem'
  }} {...others}>
    {checked &&
      <img src={CHECKED_ICON} style={{ width: `${size ? `${(size - 10)}px` : '10px'}`, height: `${size ? `${(size - 10)}px` : '10px'}` }} alt='' />
    }
  </div>
)

export type RouteSigninProps = RouteComponentProps<{ gameID: string }>;

class RouteSignin extends React.Component<RouteSigninProps, { teamMembers: number, teamLeader: number }> {

  static styles = {
    container: {
      height: "calc(100vh - 100px)",
      background: 'linear-gradient( white, #e8f9fb 51%)',
      display: 'flex',
      placeContent: 'center',
      placeItems: 'center',
      overflowY: 'auto',
      overflowX: 'hidden'
    },
    card: {
      width: '50%',
      height: "60vh",
      padding: '2.625rem',
      color: '#0d549b',
      fontSize: '1rem'
    },
    text: {
      color: '#524e4e',
      fontSize: '1.125rem',
      marginBottom: '1.25rem'
    },
    label: {
      marginBottom: '1.25rem',
    },
    input: {
      height: '65px',
      textAlign: 'left',
      marginBottom: '2.5rem',
      borderRadius: '3px',
      boxShadow: 'inset 0 1px 2px 0 rgba(0, 0, 0, 0.5)',
      border: 'solid 1px #e0e0e0',
      background: '#f8f8f8 !important',
      width: '100%'
    },
    button: {
      width: '200px',
      height: '60px',
      background: '#f44b48',
      color: 'white',
      borderRadius: '3px',
      display: 'flex',
      placeContent: 'center',
      placeSelf: 'center'
    },
    headerTable: {
      fontWeight: 'bold',
      borderBottom: 'solid 2px #e3e3e3',
      borderTop: 'solid 2px #e3e3e3'
    },
    column: {
      padding: 0,
      paddingRight: '1rem'
    }

  }

  constructor(props: RouteSigninProps) {
    super(props);
    this.state = {
      teamMembers: 2,
      teamLeader: -1
    }
  }

  secondPageLink() {
    return `/participant/games/${this.props.match.params.gameID}/signin/2`
  }

  render() {
    const { styles } = RouteSignin;
    return (
      <Layout>
        {({ components: { Card, Input, Grid, Button, Container } }) => (
          <Container style={{ ...styles.container, overflowY: 'auto', overflowX: 'hidden' }}>
            <Switch>
              <Route
                path={`${this.props.match.path}/1`}
                render={() => (
                  <Card style={styles.card}>
                    <p style={styles.text}><FormattedHTMLMessage id='signin.welcome:message' /></p>
                    <label style={{ ...styles.label, fontWeight: 'bold' }}><FormattedHTMLMessage id='signin.teamname:label' /></label>
                    <Input style={styles.input} />
                    <label style={{ ...styles.label, fontWeight: 'bold' }}><FormattedHTMLMessage id='signin.location:label' /></label>
                    <Input style={styles.input} />
                    <Button style={styles.button} onClick={() => this.props.history.push(this.secondPageLink())}>
                      <FormattedHTMLMessage id='signin.next:button' />
                    </Button>
                  </Card>
                )}
              />
              <Route
                path={`${this.props.match.path}/2`}
                render={() => (
                  <Card style={{ ...styles.card, width: '60%', height: 'unset', padding: '4.5625rem' }}>
                    <p style={{ ...styles.text, marginBottom: '2.5rem' }}><FormattedHTMLMessage id='signin.teammembers:message' /></p>
                    <Grid style={{ margin: 0 }}>
                      <Grid.Row style={styles.headerTable}>
                        <Grid.Column width={7} style={styles.column}><FormattedHTMLMessage id='signin.name:table' /></Grid.Column>
                        <Grid.Column width={7} style={styles.column}><FormattedHTMLMessage id='signin.email:table' /></Grid.Column>
                        <Grid.Column width={2} style={styles.column}><FormattedHTMLMessage id='signin.teamleader:table' /></Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid style={{ margin: 0, height: '200px', overflowY: 'scroll', overflowX: 'hidden' }}>
                      {
                        range(this.state.teamMembers).map(el => (
                          <Grid.Row>
                            <Grid.Column width={7} style={styles.column}>
                              <Input className='signin left' style={{ ...styles.input, marginBottom: 0 }} />
                            </Grid.Column>
                            <Grid.Column width={7} style={styles.column}>
                              <Input className='signin left' style={{ ...styles.input, marginBottom: 0 }} />
                            </Grid.Column>
                            <Grid.Column width={2} style={styles.column}>
                              <CustomRadio onClick={() => this.setState({ teamLeader: el })} checked={el === this.state.teamLeader} size={65} />
                            </Grid.Column>
                          </Grid.Row>
                        ))
                      }
                    </Grid>
                    <AdminButton onClick={() => this.setState({ teamMembers: this.state.teamMembers + 1 })}
                      style={{ width: 'fit-content' }}
                      full color='#0d549b'
                    >
                      <FormattedHTMLMessage id='signin.addparticipant:button' />
                    </AdminButton>
                    <Button style={styles.button}><FormattedHTMLMessage id='signin.next:button' /></Button>
                  </Card>
                )}
              />
            </Switch>
          </Container>
        )}
      </Layout>
    );
  }
}

export default RouteSignin;
