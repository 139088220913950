import React, { FC, HTMLAttributes } from 'react';
import DOWNLOAD_ICON from '../assets/download.png';
import { Layout } from './Layout';

const DownloadIcon: FC<HTMLAttributes<HTMLDivElement>> = ({ children, style, ...others }) => (
  <Layout >
    {({ components: { Image } }) => (
      <Image src={DOWNLOAD_ICON} style={{ color: '#62c2d0', width: '18px', height: '18px', ...style }} {...others} />
    )}
  </Layout>

)

export default DownloadIcon;