import PropTypes, { number } from 'prop-types';
import React, { HTMLAttributes } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Download } from '../layout/Download';
import { GameInstance } from '../model/AdminModel/Game';
import DownloadIcon from './DownloadIcon';
import { Layout } from './Layout';
import PicturesStack from './PicturesStack';
import TablePaginator from './TablePaginator';
import TrashIcon from './TrashIcon';
import ConfirmModal from './ConfirmModal';
import UPLOCK from '../assets/upPadlock.png';
import SUBLOCK from '../assets/subPadlock.png';


const props = {
  elementForPage: number.isRequired
}

type GamesListProps = PropTypes.InferProps<typeof props> & { games: GameInstance[] };


export default class GamesList extends React.Component<HTMLAttributes<HTMLDivElement> & GamesListProps, { selectedPage: number }> {

  static defaultProps = {
    elementForPage: 5
  };

  constructor(props: GamesListProps) {
    super(props);

    this.state = {
      selectedPage: 1
    }


  }

  getPagesNumber() {
    let pagesNumber = this.props.games.length / this.props.elementForPage;
    const resto = this.props.games.length % this.props.elementForPage;
    pagesNumber = parseInt(pagesNumber.toString())
    return resto !== 0 ? pagesNumber + 1 : pagesNumber;
  }

  getGamesArray() {
    const e = this.props.games.slice(
      (this.state.selectedPage - 1) * this.props.elementForPage,
      (this.state.selectedPage - 1) * this.props.elementForPage + this.props.elementForPage
    )
    return e;
  }

  render() {
    //const pagesNumber = this.getPagesNumber();
    const styles = {
      headerCell: {
        color: '#acacac', textTransform: 'uppercase', borderRightWidth: 0, borderLeftWidth: 0, paddingLeft: '14px', textAlign: 'center'
      },
      bodyCell: {
        border: '1px solid rgba(172, 172, 172, 0.33)', borderTopWidth: 0, borderRightWidth: 0, borderLeftWidth: 0, padding: '26px 14px', color: '#0d549b', fontWeight: 'bold', textAlign: 'center'
      }
    }

    return (
      <Layout>
        {({ components: { Table, Icon, Modal, Header, Button, Image } }) => (
          <Table style={{ width: '100%', margin: 'auto', paddingTop: '28px', paddingBottom: '28px', ...this.props.style }} basic='very' celled collapsing>
            <Table.Header style={{ fontSize: '12px' }}>
              <Table.Row>
                <Table.HeaderCell style={Object.assign({}, styles.headerCell, { textAlign: 'left' })}>
                  <FormattedHTMLMessage id="games.table:name" />
                </Table.HeaderCell>
                <Table.HeaderCell style={styles.headerCell}>
                  <FormattedHTMLMessage id="games.table:leader" />
                </Table.HeaderCell>
                <Table.HeaderCell style={styles.headerCell}>
                  <FormattedHTMLMessage id="games.table:completed" />
                </Table.HeaderCell>
                <Table.HeaderCell style={{ ...styles.headerCell, width: '55px', height: '47px' }}>
                  <PicturesStack anglesArray={[3, -20, 10]} size={3} borderSize={10} />
                </Table.HeaderCell>
                <Table.HeaderCell style={styles.headerCell}>
                  <FormattedHTMLMessage id="games.table:report" />
                </Table.HeaderCell>
                <Table.HeaderCell style={styles.headerCell} />
                <Table.HeaderCell style={styles.headerCell} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.getGamesArray().map((game, index) => (
                <Table.Row key={index}>
                  <Table.Cell style={{ ...styles.bodyCell, textAlign: 'left' }}>{game.teamName}</Table.Cell>
                  <Table.Cell style={styles.bodyCell}>{game.teamLeader}</Table.Cell>
                  <Table.Cell style={styles.bodyCell}>
                    <div style={{
                      display: 'inline-block',
                      backgroundColor: `${game.completed === true ? '#56df46' : '#f44b48'}`,
                      width: '12px', height: '12px',
                      margin: 'auto',
                      borderRadius: '12px'
                    }} />
                    {
                      game.completed === true ?
                        <ConfirmModal
                          trigger={<Icon style={{ cursor: 'pointer', marginLeft: '1em' }} name="undo" />}
                          header={<FormattedHTMLMessage id='modal:reopen-title' />}
                          content={<FormattedHTMLMessage id='modal:reopen-content' />}
                          confirmCallback={() => game.uncompleteGame()}
                        /> : null
                    }

                  </Table.Cell>
                  <Table.Cell style={styles.bodyCell}>{game.pictures.length}</Table.Cell>
                  <Table.Cell style={{ ...styles.bodyCell }}>
                    {
                      game.reportDocuments.map((report, j) => (
                        <Download key={j} src={report.url}>
                          <DownloadIcon style={{ display: 'inline-block' }} />
                        </Download>
                      ))
                    }
                  </Table.Cell>
                  <Table.Cell style={{ ...styles.bodyCell }}>
                    {
                      /* game.isTourOpen
                        ? (
                          <AdminButton className={'hoverHideButton'} style={{ cursor: 'pointer' }} onClick={() => game.closePlantTour()}>
                            <Image src={LOCKOFF} style={{ verticalAlign: 'middle', width: 16, height: 16, display: 'inline-block' }} />
                          </AdminButton>
                        )
                        : ( */
                      <div
                        style={{ cursor: 'pointer', position: 'relative', display: 'flex', placeContent: 'center', width: '15px', height: '15px' }}
                        onClick={() => game.togglePlantTour()}>
                        <Image src={UPLOCK} className={`${game.isTourOpen ? 'openRotation' : ''}`}
                          style={{ verticalAlign: 'middle', width: 11, transition: 'transform .5s', position: 'absolute', top: '2px' }} />
                        <Image src={SUBLOCK}
                          style={{ verticalAlign: 'middle', width: 16, position: 'absolute', top: '10px' }} />
                      </div>
                      /* ) */
                    }
                  </Table.Cell>
                  <Table.Cell style={styles.bodyCell}>
                    <Modal
                      trigger={
                        <TrashIcon />
                      }
                      header={
                        <Header style={{ fontSize: '32px', color: '#f44b48', fontWeight: 'normal', borderBottom: 0, padding: 0 }}>
                          <FormattedHTMLMessage id="modal:delete-title" defaultMessage="Are you sure to delete the game?" />
                        </Header>
                      }
                      content={
                        <div style={{ margin: "46px", fontSize: '18px', color: '#524e4e' }}>
                          <FormattedHTMLMessage id="modal:delete-description" defaultMessage=" " />
                        </div>
                      }
                      actions={[
                        <Button size="big" style={{ width: '200px', height: '60px', fontWeight: 'normal', color: 'white', backgroundColor: '#a3a3a3' }} key={"dismiss"}>
                          <FormattedHTMLMessage id="modal:dismiss" />
                        </Button>,
                        <Button
                          style={{ width: '200px', height: '60px', fontWeight: 'normal' }}
                          size="big"
                          key={"submit"}
                          color="red"
                          onClick={() => {
                            game.deleteGame()
                          }}
                        >
                          <FormattedHTMLMessage id="modal:submit" />
                        </Button>
                      ]}
                      size="small"
                      style={{
                        padding: "74px 60px",
                        backgroundColor: "white",
                        textAlign: "center",
                        width: '620px',
                        height: '324px',
                        lineHeight: 1.72
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row textAlign='center'>
                <Table.HeaderCell colSpan='6'>
                  <TablePaginator
                    selectedPage={this.state.selectedPage}
                    pagesNumber={this.getPagesNumber()}
                    onPageClick={(index: number) => this.setState({ selectedPage: index })}
                    onLeftLimitClick={() => this.setState({ selectedPage: 0 })}
                    onRightLimitClick={() => this.setState({ selectedPage: this.getPagesNumber() - 1 })}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </Layout>
    )
  }
}