import { types, IAnyModelType, resolvePath, getRoot, Instance, SnapshotOut, SnapshotIn, getSnapshot } from "mobx-state-tree";
import { autorun, IReactionDisposer } from "mobx";

export function SyncModel<T extends IAnyModelType>(Model: T) {
  return types.model(`SyncModel${Model.name}`)
    .props({
      state: types.maybeNull(Model),
      isAhead: true,
      originalStatePath: types.string,
      snapshot: types.maybeNull(types.frozen<SnapshotOut<typeof Model>>()),
      autosync: false
    })

    .actions(self=>({
      setState( snapshot: SnapshotIn<typeof Model> ) {
        self.state = Model.create(snapshot);
      },

      sync() {
        self.state = Model.create(self.snapshot);
        self.isAhead = true;
      },

      receiveUpdate(snapshot: SnapshotOut<typeof Model> ) {
        self.isAhead = false;
        self.snapshot = snapshot;
      }
    }))

    .actions(self=>{
      let disposer: IReactionDisposer;
      return {
        afterAttach() {
          const originalState: Instance<typeof Model> = resolvePath(getRoot(self), self.originalStatePath);
          const snapshot: SnapshotOut<typeof Model> = getSnapshot(originalState);
          self.state = Model.create(snapshot);
          self.snapshot = snapshot;

          disposer = autorun(() => {
            self.receiveUpdate(getSnapshot(resolvePath(getRoot(self), self.originalStatePath)))
            self.autosync && self.sync()
          })
        },
        beforeDetach() {
          disposer && disposer();
        },
        beforeDestroy() {
          disposer && disposer();
        }
      }
    })
    
}