import React, { FC, HTMLAttributes } from 'react';


const AdminSectionHeader: FC<HTMLAttributes<HTMLDivElement>> = ({ children, style }) => (
  <h1 style={{
    fontSize: '42px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#524e4e',
    marginBottom: '2rem',
    ...style
  }}>
    {children}
  </h1>
);

export default AdminSectionHeader