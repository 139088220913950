import { createBrowserHistory } from 'history';
import React from 'react';
import { Switch } from 'react-router';
import { Route, Router } from 'react-router-dom';
import './App.css';
import AppAdmin from './AppAdmin';
import AppGame from './AppGame';
import AppLogin from './AppLogin';
const history = createBrowserHistory({
  basename: window._env_.BASEPATH
})

declare global {
  interface Window { _env_: { API_ENDPOINT: string; BASEPATH: string } }
}

const App: React.FC = () => {

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route path="/participant">
            {() => (
              <AppGame mountPath="/participant" />
            )}
          </Route>
          <Route path="/admin">
            {() => (
              <AppAdmin mountPath="/admin" />
            )}
          </Route>
          <Route path='/' component={AppLogin} />
        </Switch>
      </Router>
    </div>
  );
};
export default App;
