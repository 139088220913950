import { InferProps, string } from "prop-types";
import React from "react";

const propTypes = {
  width: string,
  style: Object
};

export type DividerProps = InferProps<typeof propTypes>;

export const Divider: React.FunctionComponent<
  DividerProps & React.HTMLAttributes<HTMLElement>
> = ({ width, style }) => <hr style={{ width: "100%", opacity: 0.3, ...style }} />;
