import React from 'react';
import PlantMap from './PlantMap';
import Hotspot from './Hotspot';
import MAP from '../assets/map.png';
import { InferProps } from 'prop-types';
import { HotspotInstance } from '../model/AdminModel/Hotspot';
import { GameInstance } from '../model/AdminModel/Game';

const propTypes = {}

type Props = InferProps<typeof propTypes> & { hotspots: HotspotInstance[], location: string, game?: GameInstance | null, showConceiledHotspots: boolean };


const AdminHydroplantMap: React.FC<Props> = ({ hotspots, location, game, showConceiledHotspots }) => {

  const getDetailUrl = (hotspot: HotspotInstance) => {
    return {
      ...hotspot,
      detailUrl: `${location}/hotspots/${hotspot.id}`
    }
  }

  return (
    <PlantMap
      series={
        [{
          id: 'allHotspots',
          data: hotspots.map(getDetailUrl),
          options: {
            icon: <Hotspot levels={2} color='red' width={30} />,
            discoveredIcon: <Hotspot levels={2} color='green' width={30} />,
            selectedIcon: <Hotspot levels={2} color='gray' width={30} />
          }
        }]
      }
      game={game}
      mapUrl={MAP}
      showConceiledHotspots={showConceiledHotspots}
    />
  )
}
export default AdminHydroplantMap;
