import React, { FC, HTMLAttributes } from 'react';
import TRASH_WHITE_ICON from '../assets/trash-white.png';
import { Layout } from './Layout';

const TrashIcon: FC<HTMLAttributes<HTMLDivElement>> = ({ style }) => (
  <Layout >
    {({ components: { Image } }) => (
      <Image src={TRASH_WHITE_ICON} style={{
        color: 'white',
        width: '18px',
        height: '18px',
        cursor: 'pointer',
        ...style
      }} />
    )}
  </Layout>

)

export default TrashIcon;