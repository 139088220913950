import React, { HTMLAttributes } from "react";
import { Layout } from "./Layout";
import PaperTablet from "./PaperTablet";
import { HotspotInstance } from "../model/GameModel/Hotspot";
import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";
import keys from "lodash/keys";
import moment from "moment";
import { Tab } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";

interface Props {
  hotspot: HotspotInstance;
  onClose: Function;
}

export default class HotspotDetails extends React.Component<
  Props & HTMLAttributes<HTMLDivElement>
> {
  getTimeWasteRows(Table: any, styles: any) {
    const { hotspot } = this.props;

    if (!hotspot.wastes) return <>-</>;

    return hotspot.wastes.map((waste: any, wasteIndex: number) => 
      <Table.Row key={wasteIndex}>
        {
          this.getTimeWasteHeaders().map((wasteHeader: string, wasteHeaderIndex: number) => (
            <Table.Cell key={wasteHeaderIndex} style={styles.valueCell}>
            <p>{waste[wasteHeader] || ""}</p>
            </Table.Cell>
          ))
        }
      </Table.Row>
    )
  }

  getTimeWasteHeaders() {
    return [
      'wastePhase',
      'projectAnalysisTimeWaste',
      'materAndCompWithdrawalTimeWaste',
      'waitingAndQueuingTimeWaste',
      'withdrawalMistakesAndQualityTimeWaste',
    ];
  }

  getHeaders() {
    const { hotspot } = this.props;
    if (this.isUniqEntity()) {
      return ["Name", "Value"];
    } else {
      const headers = uniq(keys(groupBy(hotspot.attributes, "name")));
      return headers;
    }
  }

  isUniqEntity() {
    const { hotspot } = this.props;
    return keys(groupBy(hotspot.attributes, "id")).length === 1;
  }

  renderValue(attribute: any) {
    if (attribute.kind === "date") {
      const [value, unit] = attribute.value.split(" ");
      return moment().subtract(value, unit).format("DD-MM-YYYY");
    } else {
      return attribute.value;
    }
  }

  getRows(Table: any, styles: any) {
    const { hotspot } = this.props;
    if (this.isUniqEntity()) {
      return (hotspot as HotspotInstance).attributes.map(
        (attribute: any, index) => (
          <Table.Row key={index}>
            <Table.Cell width={6} style={styles.labelCell}>
              {attribute.name}
            </Table.Cell>
            <Table.Cell width={10} style={styles.valueCell}>
              {this.renderValue(attribute)}
            </Table.Cell>
          </Table.Row>
        )
      );
    } else {
      const attributesByEntity = groupBy(hotspot.attributes, "id");
      return keys(attributesByEntity).map((id, index) => {
        return (
          <Table.Row key={index}>
            {this.getHeaders().map((key, j) => {
              const attribute: any = (hotspot as HotspotInstance).attributes.find(
                (attribute) => attribute.id === id && attribute.name === key
              );
              if (attribute) {
                return (
                  <Table.Cell
                    key={j}
                    /* width={j === 0 ? 6 : 10} */ style={styles.valueCell}
                  >
                    {this.renderValue(attribute)}
                  </Table.Cell>
                );
              } else {
                return null;
              }
            })}
          </Table.Row>
        );
      });
    }
  }

  render() {
    const { hotspot } = this.props;
    const styles = {
      labelCell: {
        fontSize: "0.75rem",
        color: "#616161",
        textTransform: "uppercase",
        opacity: ".48",
      },
      valueCell: {
        fontSize: "0.6875rem",
        fontWeight: "bold",
        color: "var(--greyblue)",
        textTransform: "uppercase",
      },
    };
    return (
      <Layout>
        {({ components: { Table, Header, Image } }) => (
          <div
            style={{
              position: "absolute",
              minHeight: "700px",
              top: 0,
              right: 0,
              left: 0,
              ...this.props.style,
            }}
          >
            <PaperTablet onClose={this.props.onClose}>
              <Header
                style={{
                  margin: "9rem 0 1rem 0",
                  textAlign: "center",
                  fontSize: "1.625rem",
                  color: "#616161",
                }}
              >
                {hotspot.title}
              </Header>
              <div
                style={{
                  height: "310px",
                  width: "60%",
                  margin: "0 auto",
                  overflow: "auto",
                }}
              >
              {
                // TODO: Refactor into "drawAttributesTable" and "drawWastesTable"
                hotspot.title.toLowerCase().includes("work station") ?
                <Tab
                  panes={[
                    {
                      menuItem: "1",
                      render: () => (
                        <Table celled basic structured>
                          <Table.Header
                            style={{
                              fontSize: "0.625rem",
                              color: "#616161",
                              fontWeight: "bold",
                            }}
                          >
                            <Table.Row>
                              {this.getHeaders().map(
                                (key: string, index: number) => (
                                  <Table.HeaderCell
                                    key={index}
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {key}
                                  </Table.HeaderCell>
                                )
                              )}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>{this.getRows(Table, styles)}</Table.Body>
                        </Table>
                      ),
                    },
                    {
                      menuItem: "2",
                      render: () => (
                        <Table celled basic structured>
                          <Table.Header
                            style={{
                              fontSize: "0.625rem",
                              color: "#616161",
                              fontWeight: "bold",
                            }}
                          >
                            <Table.Row>
                              <Table.HeaderCell />
                              <Table.HeaderCell
                                style={{ textTransform: "uppercase", lineHeight: 1.4 }}
                              >
                                ASSEMBLY CYCLE
                              </Table.HeaderCell>
                              <Table.HeaderCell />
                              <Table.HeaderCell />
                              <Table.HeaderCell />
                            </Table.Row>
                            <Table.Row>
                              {this.getTimeWasteHeaders().map(
                                (twHeader, twIndex) => (
                                  <Table.HeaderCell
                                    key={twIndex}
                                    style={{ textTransform: "uppercase", lineHeight: 1.4 }}
                                  >
                                    <FormattedMessage id={`hotspot.wastes.${twHeader}`} />
                                  </Table.HeaderCell>
                                )
                              )}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {this.getTimeWasteRows(Table, styles)}
                          </Table.Body>
                        </Table>
                      ),
                    },
                  ]}
                /> : <Table celled basic structured>
                  <Table.Header
                    style={{
                      fontSize: "0.625rem",
                      color: "#616161",
                      fontWeight: "bold",
                    }}
                  >
                    <Table.Row>
                      {this.getHeaders().map(
                        (key: string, index: number) => (
                          <Table.HeaderCell
                            key={index}
                            style={{ textTransform: "uppercase" }}
                          >
                            {key}
                          </Table.HeaderCell>
                        )
                      )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>{this.getRows(Table, styles)}</Table.Body>
                </Table>
              }
              </div>
              {hotspot.image !== null && (
                <Image
                  alt=""
                  src={hotspot.image}
                  style={{
                    height: "70px",
                    margin: "2rem auto 0",
                    textAlign: "center",
                    width: "50%",
                  }}
                />
              )}
            </PaperTablet>
          </div>
        )}
      </Layout>
    );
  }
}
