import PropTypes from 'prop-types';
import React, { CSSProperties, FC, Fragment, HTMLAttributes } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Input } from 'semantic-ui-react';
import ADD_IMG from '../assets/add.png';
import AdminButton from './AdminButton';
import CreateSessionUploader from './CreateSessionUploader';
import { Layout } from './Layout';

const props = {
  onAdd: PropTypes.func
}


const Style: FC<HTMLAttributes<HTMLSpanElement> & { props?: CSSProperties }> = ({ children, props, ...others }) => (
  <span style={props} {...others}>{children}</span>
)

type AddSessionProps = PropTypes.InferProps<typeof props>;
export default class AddSession extends React.Component<AddSessionProps, { opened: boolean }> {


  constructor(props: AddSessionProps) {
    super(props);

    this.state = {
      opened: false
    }
  }

  render() {
    return (
      <Layout>
        {({ components: { Grid, Image, Container } }) => (
          <Fragment>
            <Container style={{ marginBottom: '1.125rem' }}>
              <AdminButton color='#0d549b' onClick={() => this.setState({ opened: !this.state.opened })}>
                <Image src={ADD_IMG} style={{ display: 'inline-block', marginRight: '1rem', width: '1rem' }} />
                <Style props={{ verticalAlign: 'middle' }}><FormattedHTMLMessage id='sessions.add:button' /></Style>
              </AdminButton>
            </Container>
            {this.state.opened && (
              <Container style={{
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                padding: '3rem',
                fontSize: '0.75rem',
                color: '#3a3a40',
                marginBottom: '0.75rem'
              }}>
                <Grid style={{ margin: 0 }}>
                  <Grid.Row>
                    <p><FormattedHTMLMessage id='sessions.insert:label' /></p>
                  </Grid.Row>
                  <Grid.Row >
                    <Grid.Column width={12} style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                      <Input className='left' style={{ width: '70%', textAlign: 'left', fontSize: '0.875rem' }} placeholder='Session Name' />
                      <Style props={{ marginTop: '12px' }}><FormattedHTMLMessage id='or:label' /></Style>
                      <CreateSessionUploader />
                      <Style props={{ marginTop: '5px' }} ><FormattedHTMLMessage id='sessions.upload:label' /></Style>
                    </Grid.Column>
                    <Grid.Column width={4} floated='right' textAlign='right'>
                      <AdminButton full color='#7ed321'><FormattedHTMLMessage id='sessions.save:label' /></AdminButton>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>)}
          </Fragment>
        )}
      </Layout>
    )
  }
}