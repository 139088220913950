import React, { HTMLAttributes, FC } from 'react';
import { Layout } from './Layout';
import { FormattedHTMLMessage } from 'react-intl';

import HOW_1_IMG from '../assets/how1.png';
import HOW_2_IMG from '../assets/how2.png';
import HOW_3_IMG from '../assets/how3.png';

const OnboardingCard: FC<HTMLAttributes<HTMLElement>> = () => (
  <Layout>
    {({ components: { Card, Header, Grid, Image } }) => (
      <Card
        centered
        style={{
          width: "845px",
          padding: "50px 17px 0 44px",
          height: "50vh"
        }}
      >
        <div style={{
          overflowY: "auto",
          overflowX: "hidden",
          padding: "0 3rem",
          paddingLeft: "0",
          fontSize: "16px",
          lineHeight: "1.2rem",
          color: "#524e4e"
        }} >
          <Header textAlign="center" size="large" color="red">
            <FormattedHTMLMessage id={"onboarding.card:title"} />
          </Header>
          <Header
            color="grey"
            style={{ marginBottom: "1rem" }}
            size="small"
          >
            <FormattedHTMLMessage id="step1:title" />
          </Header>
          <Grid>
            <Grid.Column width={6}>
              <Image
                fluid
                style={{ border: '1px solid rgba(0, 0, 0, 0.13)' }}
                src={HOW_1_IMG}
              />
            </Grid.Column>
            <Grid.Column width={10}>
              <div>
                <FormattedHTMLMessage id="step1:label" />
              </div>
            </Grid.Column>
          </Grid>
          <Header
            color="grey"
            style={{ marginBottom: "1rem", marginTop: '2.5rem' }}
            size="small"
          >
            <FormattedHTMLMessage id="step2:title" />
          </Header>
          <Grid>
            <Grid.Column width={6}>
              <Image
                fluid
                style={{ border: '1px solid rgba(0, 0, 0, 0.13)' }}
                src={HOW_2_IMG}
              />
            </Grid.Column>
            <Grid.Column width={10}>
              <div>
                {" "}
                <FormattedHTMLMessage id="step2:label" />
              </div>
            </Grid.Column>
          </Grid>
          <Header
            color="grey"
            style={{ marginBottom: "1rem", marginTop: '2.5rem' }}
            size="small"
          >
            <FormattedHTMLMessage id="step3:title" />
          </Header>
          <Grid>
            <Grid.Column width={6}>
              <Image
                fluid
                style={{ border: '1px solid rgba(0, 0, 0, 0.13)' }}
                src={HOW_3_IMG}
              />
            </Grid.Column>
            <Grid.Column width={10}>
              <div>
                {" "}
                <FormattedHTMLMessage id="step3:label" />
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </Card>
    )}
  </Layout>
)

export default OnboardingCard;