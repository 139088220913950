import { bool, func, InferProps, number } from "prop-types";
import React from "react";
import WHITE_ARROW from '../assets/arrow-left.png';
import { Layout } from "./Layout";

const propTypes = {
  value: number.isRequired,
  max: number.isRequired,
  step: number.isRequired,
  onChange: func.isRequired,
  white: bool,
  arrowBoxDimensions: String
};

export type ArrowControllerProps = InferProps<typeof propTypes> & {
  children: React.FunctionComponent<{ selected: number }>;
};
export type ArrowControllerType = typeof ArrowController;

export default class ArrowController extends React.Component<
  ArrowControllerProps
  > {
  static defaultProps = {
    onChange: () => { },
    step: 1,
    arrowBoxDimensions: 'small'
  };

  handleChange(value: number) {
    return this.props.onChange(
      value < 0 ? this.props.max - 1 : value % this.props.max
    );
  }

  getArrowBoxDimensions() {
    const { arrowBoxDimensions } = this.props;
    switch (arrowBoxDimensions) {
      case 'small':
        return '65%';
      case 'medium':
        return '90%';
    }
  }

  render() {
    const style = {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: "1.8rem",
      opacity: 0.3,
      cursor: "pointer"
    };



    return (
      <Layout>
        {({ components: { Icon, Image } }) => (
          <div style={{ position: "relative", width: this.getArrowBoxDimensions(), margin: "auto", height: '100%' }}>
            {this.props.white
              ? <Image src={WHITE_ARROW}
                style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', height: '30px', cursor: 'pointer' }}
                onClick={this.handleChange.bind(
                  this,
                  this.props.value - this.props.step
                )} />
              : <Icon
                name="angle left"
                size="large"
                style={{ ...style, left: "-2.3rem" }}
                onClick={this.handleChange.bind(
                  this,
                  this.props.value - this.props.step
                )}
              />
            }
            {this.props.children({ selected: this.props.value })}
            {
              this.props.white
                ? <Image src={WHITE_ARROW}
                  style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%) rotate(180deg)', height: '30px', cursor: 'pointer', right: 0 }}
                  onClick={this.handleChange.bind(
                    this,
                    this.props.value + this.props.step
                  )} />
                : <Icon
                  name="angle right"
                  size="large"
                  style={{ ...style, right: "-2.5rem" }}
                  onClick={this.handleChange.bind(
                    this,
                    this.props.value + this.props.step
                  )}
                />
            }
          </div>
        )}
      </Layout>
    );
  }
}
