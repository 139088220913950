import React, { FC } from 'react';
import CustomDropdown from './CustomDropdown';

type Props = {
  clusters: Array<any>
  onChange: (cluster: any) => void
  placeholder: string
  value: string
  disabled?: boolean
  /* rank: number */
}

const RankClusterDropdown: FC<Props> = ({ clusters, onChange, placeholder, value, disabled }) => {
  return (
    <CustomDropdown
      disabled={disabled}
      className='RankClusterDropdown'
      value={value}
      options={clusters.map(cluster => ({ text: cluster.name, value: cluster.id, key: cluster.id }))}
      placeholder={placeholder}
      onChange={(e, data) => onChange(data.value)}
      style={{
        width: '100%',
        color: '#f44b48',
        fontWeight: '300',
        backgroundColor: 'white',
        fontSize: '16px',
        height: '70px',
        padding: '26px 21px',
        display: 'flex',
        boxShadow: '0 1px 0 0 rgba(164, 164, 164, 0.24)'
      }}
    />)
}

export default RankClusterDropdown;