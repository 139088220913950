import { observer } from "mobx-react";
import React, { Fragment } from "react";
import { FormattedHTMLMessage } from "react-intl";
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import BackArrowIcon from "../components/BackArrowIcon";
import DocumentsUploader from '../components/DocumentsUploader';
import EditableDocList from '../components/EditableDocList';
import Game from "../components/Game";
import GameClassification from "../components/GameClassification";
import HotspotDetails from '../components/HotspotDetails';
import { Layout } from "../components/Layout";
import Leaderboard from "../components/Leaderboard";
import LeaderboardContainer from '../components/LeaderboardContainer';
import UserHydroplantMap from '../components/UserHydroplantMap';
import { RouteGameProps } from "./RouteGame";
import ConfirmModal from '../components/ConfirmModal';
import ClustersRankingSection from '../components/ClustersRankingSection';


const HeaderReport: React.FunctionComponent = ({ children }) => (
  <div style={{ padding: "0 10%" }}>{children}</div>
);

const SectionHeader: React.FunctionComponent = ({ children }) => (
  <h1 style={{ color: '#f44b48', fontSize: '22px', fontWeight: 'normal', marginBottom: '1.1rem' }}>
    {children}
  </h1>
);

const SectionReport: React.FunctionComponent<
  React.HTMLAttributes<HTMLElement>
> = ({ children, style }) => (
  <div style={{ margin: "64px 0", padding: "0 10%", fontSize: '16px', color: '#524e4e', ...style }}>{children}</div>
);


const RouteGameReport: React.FunctionComponent<RouteGameProps> = ({
  location,
  match: {
    params: { gameID },
    path
  }
}) => {
  return (
    <Layout>
      {({
        components: { Header, Paragraph, Button, Modal, Divider, Grid, Image, Page }
      }) => (
          <Game gameID={gameID}>
            {({ game }) => (
              <div
                style={{
                  backgroundColor: "#e8f9fb",
                  position: "absolute",
                  top: "90",
                  width: "100%",
                  padding: "3rem 0 149px 0",
                  background: 'linear-gradient( white, #e8f9fb 51%)'
                }}
              >
                <HeaderReport>
                  <Header size="huge">
                    <div style={{ fontWeight: 300, fontSize: '42px', color: 'rgb(13, 84, 155)', display: 'inline-block', verticalAlign: 'middle', marginRight: '24px' }}>
                      {game.state.team.name}
                    </div>
                    {
                      game.state.completed && (
                        <Button primary style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px', pointerEvents: 'none', pointer: 'cursor', borderRadius: 0 }}>
                          <FormattedHTMLMessage id="button:read-only:title" />
                        </Button>
                      )
                    }
                  </Header>
                  {
                    !game.state.completed && (
                      <div style={{ fontSize: '22px', color: '#524e4e' }}>
                        <Paragraph>
                          <FormattedHTMLMessage id="team.report:description" />
                        </Paragraph>
                      </div>
                    )
                  }
                  <Divider />
                </HeaderReport>
                <SectionReport>
                  {
                    !game.state.completed
                      ? (
                        <Fragment>
                          <SectionHeader>
                            <FormattedHTMLMessage id="upload.report:title" />
                          </SectionHeader>
                          <Paragraph>
                            <FormattedHTMLMessage id="upload.report:description" />
                          </Paragraph>
                        </Fragment>
                      )
                      : null
                  }
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={8}>

                        {
                          game.state.completed
                            ? (
                              <SectionHeader>
                                <FormattedHTMLMessage id="your.report:title" />
                              </SectionHeader>
                            )
                            : null
                        }
                        {
                          game.state.completed
                            ? <EditableDocList edit={false} documents={game.state.reportDocuments} />
                            : <DocumentsUploader onUpload={(file: File) => game.addReportDocument(file)} />
                        }
                      </Grid.Column>
                      <Grid.Column width={8}>
                        {
                          game.state.completed
                            ? (
                              <SectionHeader>
                                <FormattedHTMLMessage id="netxSteps.report:title" />
                              </SectionHeader>
                            )
                            : null
                        }
                        {
                          !game.state.completed
                            ? <EditableDocList edit={true} documents={game.state.reportDocuments} onDelete={game.deleteReportDocument} />
                            : <EditableDocList edit={false} documents={game.state.sessionDocuments} />
                        }

                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </SectionReport>
                <SectionReport>
                  <SectionHeader>
                    {
                      game.state.completed
                        ? <FormattedHTMLMessage id="yourPhotos.report:title" />
                        : <FormattedHTMLMessage id="classification.report:title" />
                    }
                  </SectionHeader>
                  {
                    !game.state.completed && (
                      <Paragraph>
                        <FormattedHTMLMessage id="classification.report:description" />
                      </Paragraph>
                    )
                  }
                  <GameClassification gameID={gameID} />
                </SectionReport>
                <SectionReport>
                  <SectionHeader>
                    <FormattedHTMLMessage id='cluster-ranking-section:title' />
                  </SectionHeader>
                  <ClustersRankingSection clusters={game.state.clusters} 
                    onSelectCluster={game.setClusterRank} 
                    rankedClusters={game.state.rankedClustersSorted}
                    disabled={game.state.completed} />
                </SectionReport>
                {!game.state.completed && (
                  <SectionReport>
                    <Divider />
                    <ConfirmModal
                      trigger={
                        <Button primary style={{ width: '200px', fontSize: '20px', fontWeight: 'normal' }}>
                          <FormattedHTMLMessage id="modal:submit-open" />
                        </Button>
                      }
                      header={<FormattedHTMLMessage id="modal:submit-title" />}
                      content={<FormattedHTMLMessage id="modal:submit-description" />}
                      confirmCallback={() => game.commitCompletion()}
                    />
                  </SectionReport>
                )}
                {game.state.completed && (
                  <SectionReport>
                    <SectionHeader >
                      <FormattedHTMLMessage id="results.report:title" />
                    </SectionHeader>
                    <div style={{ backgroundColor: 'white', padding: '3.5rem', position: 'relative' }}>
                      <UserHydroplantMap location={location.pathname} game={game} />
                      <Route path={`${path}/hotspots/:hotspotID`} render={({ history, match }) => {
                        const hotspot = game.getHotspot(match.params.hotspotID);
                        return hotspot && <HotspotDetails hotspot={hotspot} onClose={() => history.push(`/participant/games/${game.id}/report`)} />
                      }} />
                    </div>
                  </SectionReport>
                )}
                {game.state.isLeaderboardEnabled && (
                  <SectionReport>
                    <SectionHeader>
                      <FormattedHTMLMessage id="leaderbord.report:title" />
                    </SectionHeader>
                    <LeaderboardContainer>
                      {game.state.leaderboard && <Leaderboard leaderboard={game.state.leaderboard} />}
                    </LeaderboardContainer>
                  </SectionReport>

                )}
                {game.state.isTourOpen && !game.state.completed && (
                  <Page.BottomBar
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      position: "absolute",
                      width: "100%",
                      height: '62px',
                      bottom: "0",
                      left: "0"
                    }}>
                    <Grid
                      stretched
                      verticalAlign="middle"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        margin: 0,
                        padding: "0 1rem",
                        height: '100%'
                      }}
                    >
                      <Grid.Column
                        width={16}
                        style={{
                          padding: 0
                        }}
                      >
                        <Link to={`/participant/games/${gameID}/virtual-tour`}
                          style={{ color: "#a2a2a2", display: 'flex' }}
                        >
                          <BackArrowIcon style={{ display: 'inline-block', marginRight: '10px' }} />
                          <FormattedHTMLMessage id="backToTour:text" />
                        </Link>
                      </Grid.Column>
                    </Grid>
                  </Page.BottomBar>
                )}
              </div>

            )}
          </Game>
        )}
    </Layout>
  );
};

export default observer(RouteGameReport);
