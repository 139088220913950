import React, { HTMLAttributes, FC } from 'react';

type Props = {
  size?: number,
  border?: number,
  disabled: boolean,
}

const TakePictureButton: FC<HTMLAttributes<HTMLDivElement> & Props> =
  ({ children, style, onClick, size: sizeProps, disabled, border }) => {
    const size = sizeProps || 100;
    const internalSize = size - (border || 10);


    return (
      <div style={{
        width: size, height: size,
        backgroundColor: `${disabled ? '#f44b48' : 'rgba(255, 255, 255, 0.5)'}`,
        transition: 'background-color .1s',
        borderRadius: '50%',
        cursor: 'pointer',
        ...style,
        position: 'relative'
      }} onClick={!disabled ? onClick : () => false} >
        <div style={{
          position: 'absolute',
          width: internalSize, height: internalSize,
          borderRadius: '50%', border: '1px solid white',
          margin: '0 auto',
          left: 0, right: 0,
          top: '50%',
          transform: 'translateY(-50%)',

        }}>
          {children}
        </div>
      </div>
    )
  }

export default TakePictureButton;