import { Page } from "./Page";
import { Inline } from "./Inline";
import { Paragraph } from "./Paragraph";
import { Picture } from "./Picture";
import { Download } from "./Download";
import { Divider } from "./Divider";
import { TextInput } from "./TextInput";
import Container from './Container';
import { Button, Icon, Segment, Header, Card, Image, Grid, Modal, Sidebar, Dropdown, Table, Radio, Select, Menu, Input } from 'semantic-ui-react';


export default {
  Page,
  Button,
  Radio,
  Header,
  Paragraph,
  Card,
  Picture,
  Icon,
  Inline,
  Download,
  Divider,
  Segment,
  Image,
  Grid,
  TextInput,
  Modal,
  Sidebar,
  Dropdown,
  Table,
  Select,
  Menu,
  Container,
  Input
}