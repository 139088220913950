import React, { FC } from 'react';
import LOW_RES from '../assets/LowRes.png';
import HydroplantHeader from './HydroplantHeader';
import { Layout } from './Layout';

const LowRes: FC = () => (
  <Layout>
    {({ components: { Image } }) => (
      <div className='lowRes' style={{
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        // background: '-webkit-radial-gradient(58.69% 39.44%,790.87px,#2764ff 0,#003a69 100%)',
        backgroundRepeat: 'repeat',
        display: 'flex',
        color: '#2764ff',
        textAlign: 'center',
        fontFamily: 'Lato, sans-serif',
        justifyContent: 'center',
        backgroundColor: 'white'
      }}>
        <div className='lowResHeader' style={{
          backgroundColor: '#002855',
          height: '100px',
          width: '100%',
          color: 'white',
          display: 'flex',
          padding: '0 33px',
          alignItems: 'center',
          justifyContent: 'space-between',
          zIndex: 99999,
          fontWeight: 400,
          letterSpacing: 0,
          lineHeight: '22px',
        }}>
          <HydroplantHeader />
        </div>
        <div style={{
          height: 'calc(100vh - 100px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{
            maxWidth: '75%',
            margin: 'auto'
          }}>
            <p style={{
              fontSize: '7vh',
              fontWeight: 700,
              marginBottom: 0
            }}>Ouch!</p>
            <p style={{
              fontSize: '4vh',
              fontWeight: 700,
              marginBottom: 0

            }}>Hydroplant non supporta la risoluzione dello schermo del tuo device.</p>
            <Image src={LOW_RES} style={{
              width: '75px',
              margin: '1vh auto',

            }} />
            <p style={{
              fontSize: '4vh',
              marginBottom: 0
            }}>Per favore accedi all'app da un device desktop o tablet con risoluzione dello schermo non inferiore a 950x670px</p>
          </div>
        </div>
      </div>
    )}
  </Layout>
)
export default LowRes;