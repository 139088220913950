import React, { FC, HTMLAttributes } from 'react';
import BackArrow from '../assets/backArrow.png';
import { Layout } from './Layout';

const BackArrowIcon: FC<HTMLAttributes<HTMLDivElement>> = ({ children, style, ...others }) => (
  <Layout >
    {({ components: { Image } }) => (
      <Image src={BackArrow} style={{
        width: '10px', height: '15px', cursor: 'pointer', opacity: 0.5, ...style
      }} />
    )}
  </Layout>

)

export default BackArrowIcon;