import React, { FC, Fragment, HTMLAttributes } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { Route, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import Admin from "../components/Admin";
import AdminButton from "../components/AdminButton";
import AdminClassificationDebrief from "../components/AdminClassificationDebrief";
import AdminHydroplantMap from "../components/AdminHydroplantMap";
import AdminSectionHeader from "../components/AdminSectionHeader";
import GameSelector from "../components/GameSelector";
import HotspotDetails from "../components/HotspotDetails";
import { Layout } from "../components/Layout";

export type RouteSessionDebriefProps = RouteComponentProps<{
  userID: string;
  sessionID: string;
}>;

const AdminSection: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...others
}) => (
  <div style={{ margin: "2rem 0" }} {...others}>
    {children}
  </div>
);

class RouteSessionDebrief extends React.Component<RouteSessionDebriefProps> {
  state: { showConceiledHotspots: boolean };

  constructor(props: RouteSessionDebriefProps) {
    super(props);

    this.state = {
      showConceiledHotspots: false,
    };
  }

  toggleConceiledHotspots = () =>
    this.setState({ showConceiledHotspots: !this.state.showConceiledHotspots });

  render() {
    const { userID, sessionID } = this.props.match.params;
    return (
      <Admin userID={userID}>
        {({ admin }) => (
          <Layout>
            {({ components: { Divider, Grid } }) => {
              const session = admin.getSession(sessionID);
              if (session) {
                return (
                  <Fragment>
                    <AdminSection>
                      <Grid padded={"horizontally"}>
                        <Grid.Row>
                          <Grid.Column width={10}>
                            <AdminSectionHeader style={{ marginBottom: 0 }}>
                              {session.name}
                            </AdminSectionHeader>
                          </Grid.Column>
                          <Grid.Column
                            width={6}
                            floated="right"
                            textAlign="right"
                          >
                            <Link
                              to={`/admin/users/${userID}/sessions/${session.id}`}
                            >
                              <AdminButton color="#0d549b">
                                <FormattedHTMLMessage id="backToSession:button" />
                              </AdminButton>
                            </Link>
                          </Grid.Column>
                        </Grid.Row>
                        <Divider />
                        <Grid.Row>
                          <Grid.Column
                            width={6}
                            style={{
                              fontSize: "22px",
                              color: "#524e4e",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <FormattedHTMLMessage id="session.debrief:selectTeam" />
                          </Grid.Column>
                          <Grid.Column>
                            <GameSelector
                              games={session.games}
                              onSelect={(game) => admin.setSelectedGame(game)}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </AdminSection>
                    <AdminSection>
                      <AdminSectionHeader>
                        <FormattedHTMLMessage id="debrief.photos:title" />
                      </AdminSectionHeader>
                      <AdminClassificationDebrief userID={userID} />
                    </AdminSection>
                    <AdminSection>
                      <AdminSectionHeader>
                        <FormattedHTMLMessage id="admin.hotspot:title" />
                      </AdminSectionHeader>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          padding: "20px",
                        }}
                      >
                        {admin.selectedGame && (
                          <div className="ui toggle checkbox">
                            <input
                              type="checkbox"
                              name="show"
                              value={this.state.showConceiledHotspots ? 1 : 0}
                              onChange={this.toggleConceiledHotspots}
                              checked={this.state.showConceiledHotspots}
                            />
                            <label>
                              <FormattedMessage id="plantMap:show-conceiled-hotspots" />
                            </label>
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          backgroundColor: "white",
                          position: "relative",
                        }}
                      >
                        {session && session.hotspots && (
                          <AdminHydroplantMap
                            location={this.props.location.pathname}
                            hotspots={session.hotspots}
                            game={admin.selectedGame}
                            showConceiledHotspots={
                              admin.selectedGame
                                ? this.state.showConceiledHotspots
                                : true
                            }
                          />
                        )}
                        <Route
                          path={`${this.props.match.path}/hotspots/:hotspotID`}
                          render={({ history, match }) => (
                            <Admin userID={userID}>
                              {({ admin }) => {
                                const hotspot = admin.getHotspot(
                                  match.params.sessionID,
                                  match.params.hotspotID
                                );
                                return hotspot ? (
                                  <HotspotDetails
                                    hotspot={hotspot}
                                    onClose={() =>
                                      history.push(
                                        `/admin/users/${userID}/sessions/${session.id}/debrief`
                                      )
                                    }
                                  />
                                ) : null;
                              }}
                            </Admin>
                          )}
                        />
                      </div>
                    </AdminSection>
                  </Fragment>
                );
              } else {
                return null;
              }
            }}
          </Layout>
        )}
      </Admin>
    );
  }
}

export default RouteSessionDebrief;
