import { types, Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree";
import { Picture } from "./Picture";

export const Cluster = types.model('Cluster')
  .props({
    id: types.string,
    name: types.string,
    pictures: types.array(Picture),
    unit: types.string
  })
  .actions(self => ({
    setName(name: string) {
      self.name = name;
    },
    setUnit(unit: string) {
      self.unit = unit;
    }
  }))

export interface ClusterInstance extends Instance<typeof Cluster> { }
export interface ClusterSnapshotIn extends SnapshotIn<typeof Cluster> { }
export interface ClusterSnapshotOut extends SnapshotOut<typeof Cluster> { }