import { arrayOf, bool, element, InferProps, number, shape, string, any } from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Layout } from './Layout';

const propTypes = {
  series: arrayOf(
    shape({
      id: string,
      data: arrayOf(shape({
        id: string,
        title: string,
        description: string,
        xstring: number,
        y: number,
        detailUrl: string
      }).isRequired).isRequired,
      options: shape({
        color: string,
        icon: element.isRequired,
        selectedIcon: element,
        discoveredIcon: element,
        showInLegend: bool,
      }).isRequired
    }).isRequired
  ).isRequired,
  mapUrl: string,
  showLegend: bool,
  game: shape({
    id: string,
    teamName: string,
    teamLeader: string,
    location: string,
    completed: bool,
    isTourOpen: bool,
    reportDocuments: any,
    pictures: any,
    clusters: arrayOf(any),
    rankedClusters: arrayOf(shape({ clusterID: string, rank: number })),
    discoveredHotspots: arrayOf(any)
  }), 
  showConceiledHotspots: bool
}

export type PlantMapProps = InferProps<typeof propTypes>;
export type PlantMapType = typeof PlantMap;

type State = {
  hotspotDetail: string
}

const Map: React.FC<{ src: string }> = ({ src }) => (
  <img src={src} style={{ maxWidth: '100%', maxHeight: '100%' }} alt=''></img>
)

const Modal: React.FC = ({ children }) => (
  <div style={{
    position: 'absolute',
    padding: '1.5rem',
    color: '#616161',
    backgroundColor: 'white',
    top: 0,
    left: '35px',
    fontSize: '1rem',
    boxShadow: '0 5px 20px 0 rgba(0, 0, 0, 0.5)',
    zIndex: 10,
    width: 250
  }}>{children}</div>
)

const Legend: React.FC = ({ children }) => (
  <div style={{
    position: 'absolute',
    width: '200px',
    right: '0',
    top: '0',
    backgroundColor: 'white',
    border: '1px solid black',
    fontSize: '.8rem'
  }}>
    <div style={{ borderBottom: '1px solid black', padding: '1rem' }}>
      <b>
        <FormattedHTMLMessage id='legend.title:label' />
      </b>
    </div>
    <div style={{ padding: '1rem' }}>{children}</div>
  </div>
)

export default class PlantMap extends React.Component<PlantMapProps, State> {

  constructor(props: PlantMapProps) {
    super(props);

    this.state = {
      hotspotDetail: ''
    }
  }

  onHotspotClick(id: string) {
    this.setState({
      hotspotDetail: id !== this.state.hotspotDetail ? id : ''
    })
  }

  render() {
    return (
      <Layout>

        {({ components: { Grid } }) => (
          <div style={{ position: 'relative' }}>
            <div style={{
              position: 'relative',
              margin: '0 20%',
            }}>
              <Map src={this.props.mapUrl || ''} />
              {this.props.series.map((serie: any) => {
                return serie.data.map((data: any) => {
                  const isSelected: boolean = !!(
                    this.state.hotspotDetail === data.id &&
                    serie.options.selectedIcon
                  );

                  const isDiscovered: boolean = !!(
                    (this.props.game &&
                      this.props.game
                        .discoveredHotspots &&
                      this.props.game.discoveredHotspots.find(
                        (dg: any) => dg.id === data.id
                      )) ||
                    serie.id === "discoveredHotspot"
                  );

                  const icon = serie.id === "currentPosition"
                    ? serie.options.icon
                    : isSelected
                    ? serie.options.selectedIcon
                    : isDiscovered
                    ? serie.options.discoveredIcon || serie.options.icon
                    : this.props.showConceiledHotspots
                    ? serie.options.icon
                    : null;
                  
                  return(
                  <Fragment key={data.id}>
                    <div
                      className={`${data.id}`}
                      style={{
                        position: 'absolute',
                        bottom: `${data.y}%`,
                        left: `${data.x}%`,
                        cursor: `${data.detailUrl ? 'pointer' : ''}`,
                      }}
                      onClick={() => this.onHotspotClick(data.id)}>
                        {icon}

                        {
                          this.state.hotspotDetail === data.id && data.detailUrl &&
                          <Modal>
                            <Link to={`${data.detailUrl}`}>
                              <span style={{
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                width: '80%',
                              }}>
                                {data.title}
                              </span>
                              <span style={{
                                display: 'inline-block',
                                width: '20%',
                                verticalAlign: 'top',
                                color: '#f44b48',
                                paddingLeft: '0.5rem',
                                fontSize: '2rem',
                                position: 'relative',
                                top: '4px'
                              }}>+</span>
                            </Link>
                          </Modal>
                        }
                    </div>
                  </Fragment>
                )})
              })}

            </div>
            {this.props.showLegend &&
              <Legend>
                <Grid columns={16} verticalAlign='middle'>
                  {
                    this.props.series.map((serie: any) => (
                      <Grid.Row key={serie.id}>
                        <Grid.Column style={{ width: '50px', paddingRight: '0' }}>{serie.options.icon}</Grid.Column>
                        <Grid.Column width={10}><FormattedHTMLMessage id={`legend.serie.${serie.id}:label`} /></Grid.Column>
                      </Grid.Row>
                    ))
                  }
                </Grid>
              </Legend>}
          </div>
        )
        }
      </Layout>
    )
  }
}
