import { arrayOf, func, InferProps, shape, string } from "prop-types";
import React, { useState } from "react";
import { FormattedHTMLMessage } from "react-intl";
import { Layout } from "./Layout";

const propTypes = {
  clusters: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired
    })
  ).isRequired,
  onClusterSelect: func.isRequired
};

type PropTypes = InferProps<typeof propTypes>;

const ClusterSelector: React.FunctionComponent<
  PropTypes & React.HTMLAttributes<HTMLElement>
> = ({ clusters, style, onClusterSelect }) => {
  const options = clusters.map(c =>
    c ? { key: c.id, text: c.name, value: c.id } : {}
  );

  const [state, setState] = useState({
    selected: ''
  })

  return (
    <Layout>
      {({ components: { Dropdown, Button } }) => (
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "2rem",
            display: "flex",
            placeContent: "space-around",
            placeItems: "center",
            padding: "0.5rem 1rem",
            fontSize: "15px",
            textTransform: "uppercase",
            color: "rgb(82, 78, 78)",
            ...style
          }}
        >
          <FormattedHTMLMessage id="clusterSelect:label" />
          <Dropdown placeholder="Clusters"
            options={options}
            onChange={(e, data) => { setState({ selected: data.value ? data.value.toString() : '' }) }} />
          <Button
            primary
            size="tiny"
            style={{
              padding: "0.3rem",
              borderRadius: "5px",
              marginLeft: "0.5rem",
              width: "5rem",
              fontSize: "15px",
              fontWeight: "bold",
              textTransform: "capitalize"
            }}
            onClick={() => onClusterSelect(state.selected)}
            disabled={state.selected === ''}
          >
            <FormattedHTMLMessage id="clusterSelect.submit:button" />
          </Button>
        </div>
      )}
    </Layout>
  );
};

export default ClusterSelector;
