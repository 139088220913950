import React from 'react';
import { string, InferProps } from 'prop-types';

const propTypes = {
  src: string
}

export type DownloadProps = InferProps<typeof propTypes>;
export type DownloadType = typeof Download;

export const Download: React.SFC<DownloadProps> = ({ children, src }) => (
  <a href={src || ""} download>{children}</a>
)