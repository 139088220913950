import React from 'react';
import { GameInstance } from '../model/AdminModel/Game';
import CustomDropdown from './CustomDropdown';

type GameSelectorProps = {
  games: GameInstance[],
  onSelect: (game: GameInstance) => void
};

const GameSelector: React.FC<GameSelectorProps> = ({ games, onSelect }) => {
  return (
    <CustomDropdown
      className='GameSelector'
      placeholder='Teams'
      options={games.map(game => ({ key: game.id, value: game.id, text: game.teamName }))}
      onChange={(e, data) => {
        const game = games.find(game => game.id === data.value);
        game && onSelect(game)
      }} />
  )
}

export default GameSelector;