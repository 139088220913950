import { types, Instance, SnapshotIn, SnapshotOut, getEnv } from "mobx-state-tree";
import { Leaderboard } from "./Leaderboard";
import { Game } from "./Game";
import { Document } from "./Document";
import { Hotspot } from "./Hotspot";
import { gql, NormalizedCacheObject } from 'apollo-boost';
import { ApolloClient } from 'apollo-client';

export const Session = types.model('Session')
  .props({
    id: types.string,
    name: types.maybeNull(types.string),
    hotspots: types.maybeNull(types.array(Hotspot)),
    isLeaderboardEnabled: false,
    isTourOpen: false,
    date: types.maybeNull(types.string),
    documents: types.array(Document),
    games: types.array(Game),
    leaderboard: Leaderboard
  })

  .views(self => ({
    get client() {
      return getEnv<{ client: ApolloClient<NormalizedCacheObject> }>(self)
        .client
    }
  }))

  .actions(self=>({

    deleteSession() {
      self.client.mutate({
        mutation: gql`
          mutation DeleteSession($sessionID:ID!) {
            deleteSession( sessionID: $sessionID ) {
              commandID
              rejectionReason
            }
          }
        `,
        variables: {
          sessionID: self.id
        }
      })
    },

    closePlantTour() {
      self.client.mutate({
        mutation: gql`
          mutation CloseTour($sessionID:ID!) {
            closeTour( sessionID: $sessionID ) {
              commandID
              rejectionReason
            }
          }
        `,
        variables: {
          sessionID: self.id
        }
      })
    },

    openPlantTour() {
      self.client.mutate({
        mutation: gql`
          mutation OpenTour($sessionID:ID!) {
            openTour( sessionID: $sessionID ) {
              commandID
              rejectionReason
            }
          }
        `,
        variables: {
          sessionID: self.id
        }
      })
    },

    addGame(sessionID: string, data: any) { },
    deleteGame(gameID: string) { console.log(gameID) },

    addSessionDocument(file: File) {
      self.client.mutate({
        mutation: gql`
          mutation AddSessionDocument($sessionID:ID!, $file:Upload!) {
            addSessionDocument( sessionID: $sessionID, file:$file ) {
              commandID
              rejectionReason
            }
          }
        `,
        variables: {
          sessionID: self.id,
          file
        }
      })
    },
    removeSessionDocument(documentID: string) {
      self.client.mutate({
        mutation: gql`
          mutation DeleteSessionDocument($sessionID:ID!, $documentID:ID!) {
            deleteSessionDocument( sessionID: $sessionID, documentID:$documentID ) {
              commandID
              rejectionReason
            }
          }
        `,
        variables: {
          sessionID: self.id,
          documentID
        }
      })
    },
    showLeaderboard() {
      self.client.mutate({
        mutation: gql`
          mutation EnableLeaderboard($sessionID:ID!) {
            enableLeaderboard( sessionID: $sessionID ) {
              commandID
              rejectionReason
            }
          }
        `,
        variables: {
          sessionID: self.id
        }
      })
    },
    hideLeaderboard() {
      self.client.mutate({
        mutation: gql`
          mutation DisableLeaderboard($sessionID:ID!) {
            disableLeaderboard( sessionID: $sessionID ) {
              commandID
              rejectionReason
            }
          }
        `,
        variables: {
          sessionID: self.id
        }
      })
    },
  }))

export interface SessionInstance extends Instance<typeof Session> { }
export interface SessionSnapshotIn extends SnapshotIn<typeof Session> { }
export interface SessionSnapshotOut extends SnapshotOut<typeof Session> { }
