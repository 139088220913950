import { observer } from "mobx-react";
import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { ClusterInstance } from "../model/GameModel/Cluster";
import DottedCircleContainer from "./DottedCircleContainer";
import { Layout } from "./Layout";
import PicturesStack from "./PicturesStack";

const ClusterPicturesLength: React.FC<{ value: number }> = ({ value }) => (
  <Layout>
    {({ components: { Paragraph } }) => (
      <div style={{ fontWeight: "bold", color: "#0d549b" }}>
        <Paragraph>
          {value}{" "}
          <small>
            <FormattedHTMLMessage
              id="photos.added"
              defaultMessage="photos added"
            />
          </small>
        </Paragraph>
      </div>
    )}
  </Layout>
);

type Props = {
  cluster: ClusterInstance;
  active?: boolean;
  onClick: (e: React.MouseEvent, cluster: ClusterInstance) => void;
};

const Clusters: React.FC<Props> = ({ cluster, active = true, onClick }) => (
  <div
    className="clusters-container"
    style={{ opacity: active ? "1" : "0.4", cursor: "pointer" }}
  >
    {cluster.name}
    <br />
    <span style={{ color: "rgb(110, 110, 110)" }}>{cluster.unit}</span>
    <DottedCircleContainer
      active={active}
      size={"114px"}
      onClick={(e) => {
        onClick(e, cluster);
      }}
    >
      <PicturesStack
        pictures={cluster.pictures}
        size={2}
        borderSize={10}
        anglesArray={[-20, 3]}
      />
    </DottedCircleContainer>
    <ClusterPicturesLength value={cluster.pictures.length} />
  </div>
);

export default observer(Clusters);
