import React, { Fragment } from 'react';
import { RouteComponentProps } from "react-router";
import Game from '../components/Game';
import HotspotDetails from '../components/HotspotDetails';

class RouteHotspotDetails extends React.Component<RouteComponentProps<{ gameID: string, hotspotID: string }>> {
  render() {
    return (
      <Game gameID={this.props.match.params.gameID}>
        {({ game }) => {
          const hotspot = game.getHotspot(this.props.match.params.hotspotID);
          return (
            <Fragment>
              {hotspot && (
                <HotspotDetails
                  hotspot={hotspot}
                  onClose={() => { this.props.history.push(`/participant/games/${game.id}/plant-map`) }}
                  style={{ position: 'relative' }}
                />
              )}
            </Fragment>
          )
        }}
      </Game>
    )
  }
}

export default RouteHotspotDetails;