import React, { FC, useState, Fragment } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ZOOM_ICON from '../assets/zoom.png';
import { GameModelInstance } from '../model/GameModel';
import CloseIcon from './CloseIcon';
import DownloadIcon from './DownloadIcon';
import { Layout } from './Layout';
import PicturesStack from './PicturesStack';
import TrashIcon from './TrashIcon';
import WelcomeTeamName from './WelcomeTeamName';

type HydroplantSidebarProps = {
  game: GameModelInstance,
  visible?: boolean,
  closeCallback: () => any,
  selectCallback: Function
}

const HydroplantSidebar: FC<HydroplantSidebarProps> = ({ game, visible = false, closeCallback, selectCallback }) => {

  const [hoverPic, setHoverPic] = useState('');

  return (
    <Layout>
      {({ components: { Icon, Header, Download, Sidebar, Image, Grid } }) => (
        <Fragment>
          <Sidebar className='PhotosSidebar' animation='overlay' visible={visible} direction={'right'} style={{
            position: 'absolute',
            top: '0',
            backgroundColor: 'white',
            boxShadow: '-10px 0 214px 0 rgba(0, 0, 0, 0.17)',
            padding: '4rem 2rem 2rem 3rem',
            zIndex: 99999999
          }}
            width={'wide'}  >
            <Grid>
              <Grid.Column width={13}>
                <div style={{ textAlign: 'left' }} >
                  <WelcomeTeamName style={{ backgroundColor: 'transparent', verticalAlign: 'top', padding: '0', border: '0' }}>{game.state.team.name}</WelcomeTeamName>
                </div>
              </Grid.Column>
              <Grid.Column width={3} floated={'right'} textAlign='right'>
                <CloseIcon
                  color='red'
                  onClick={closeCallback}
                  style={{ top: 0, right: 0 }}
                />
              </Grid.Column>
            </Grid>
            <Grid verticalAlign='middle'>
              <Grid.Column style={{ width: '90px', height: '90px' }}>
                <PicturesStack anglesArray={[3, -20, 10]} size={3} borderSize={10} />
              </Grid.Column>
              <Grid.Column width={5}>
                <Header style={{ fontSize: '1.375rem', color: '#524e4e', fontWeight: '400' }}>
                  <FormattedHTMLMessage id="photos:title" />
                </Header>
              </Grid.Column>
              <Grid.Column width={5} textAlign={'right'} floated='right' style={{ fontSize: '1.375rem' }}>
                <span style={{ color: '#524e4e' }}>(</span>
                <span style={{ color: '#62c2d0' }}>{game.state.pictures.length}</span>
                <span style={{ color: '#524e4e', fontSize: '16px' }}>/{game.state.picturesLimit}</span>
                <span style={{ color: '#524e4e' }}>)</span>
              </Grid.Column>
            </Grid>
            <Grid style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '65vh', paddingRight: '10px' }}>
              {game.state.pictures.map((picture, index) => (
                <Grid.Row key={index} style={{
                  height: '93px',
                  borderBottom: '1px solid #dfdfdf',
                  borderTop: index === 0 ? '1px solid #dfdfdf' : ''
                }}>
                  <Grid.Column width={7} textAlign='center' style={{ position: 'relative', paddingLeft: 0, paddingRight: 0, maxHeight: '100%' }}>
                    <Image
                      src={picture.url}
                      onMouseEnter={() => setHoverPic(picture.id)}
                      onMouseLeave={() => setHoverPic('')}
                      onClick={() => selectCallback(index)}
                      style={{
                        filter: hoverPic === picture.id ? 'grayscale(80%)' : '',
                        cursor: 'pointer',
                        maxHeight: '100%',
                        margin: '0 auto',
                      }}
                    />
                    {hoverPic === picture.id &&
                      <Image src={ZOOM_ICON} style={{
                        position: 'absolute',
                        width: '20px',
                        left: '50%',
                        transform: 'translate(-50%)',
                        top: '50%',
                        marginTop: '-10px',
                        zIndex: 1000,
                        pointerEvents: 'none'
                      }}
                      />}
                  </Grid.Column>
                  <Grid.Column width={3} floated={'right'} verticalAlign={'middle'} textAlign='right' style={{ padding: 0 }} >
                    <Download src={picture.url}>
                      <DownloadIcon style={{ display: 'inline-block' }} />
                    </Download>
                  </Grid.Column>
                  <Grid.Column onClick={() => game.deletePicture(picture.id)} width={3} verticalAlign={'middle'} textAlign='right' style={{ padding: 0 }}>
                    <TrashIcon style={{ display: 'inline-block' }} />
                  </Grid.Column >
                </Grid.Row>
              ))}
            </Grid>

          </Sidebar>
        </Fragment>
      )}
    </Layout>
  )
}

export default HydroplantSidebar;