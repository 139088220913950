import { types, Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree";

export const Team = types.model('Team')
  .props({
    id: types.string,
    name: types.string,
  })

export interface TeamInstance extends Instance<typeof Team> { }
export interface TeamSnapshotIn extends SnapshotIn<typeof Team> { }
export interface TeamSnapshotOut extends SnapshotOut<typeof Team> { }