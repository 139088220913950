import { InferProps, string } from "prop-types";
import React from "react";
import { Layout, LayoutContextProps } from "./Layout";

const propTypes = {
  size: string,
  color: string
};

export type ButtonProps = InferProps<typeof propTypes> &
  LayoutContextProps["Button"];

const ActionButton: React.SFC<ButtonProps> = ({
  children,
  style,
  ...others
}) => (
    <Layout>
      {({ components: { Button } }) => (
        <Button
          primary
          style={{
            width: "300px",
            fontSize: "1.5rem",
            padding: "1.5rem",
            ...style
          }}
          {...others}
        >
          {children}
        </Button>
      )}
    </Layout>
  );

export default ActionButton;
