import React from "react";

const Header: React.SFC<React.HTMLAttributes<HTMLElement>> = ({
  children,
  ...others
}) => {
  return <div {...others}>{children}</div>;
};

const Layer: React.SFC<React.HTMLAttributes<HTMLElement>> = ({
  children,
  ...others
}) => {
  return (
    <div className="page-layer" {...others}>
      {children}
    </div>
  );
};

const BottomBar: React.SFC<React.HTMLAttributes<HTMLElement>> = ({
  children,
  style,
  ...others
}) => {
  return (
    <div {...others} style={{ fontSize: ".75rem", ...style }}>
      {children}
    </div>
  );
};

const SideBar: React.SFC = ({ children }) => {
  return (
    <div
      style={{
        position: "fixed",
        backgroundColor: "red",
        height: "100vh",
        width: "30vw",
        right: 0,
      }}
    >
      {children}
    </div>
  );
};

export type PageType = typeof Page;

export class Page extends React.Component<React.HTMLAttributes<HTMLElement>> {
  static Layer = Layer;
  static BottomBar = BottomBar;
  static SideBar = SideBar;
  static Header = Header;

  render() {
    return (
      <div
        className={this.props.className && this.props.className}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}
