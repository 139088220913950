import React from 'react';

const RectIconButton: React.SFC = ({ children }) => (
  <div style={{
    textTransform: 'uppercase',
    fontWeight: 'bold',
    width: '100px',
    textAlign: 'center',
    backgroundColor: 'white',
    height: '101px',
    fontSize: '.75rem',
    color: '#acacac',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.21)'
  }}>
    {children}
  </div>
)

export default RectIconButton;