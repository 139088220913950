import React, { HTMLAttributes, FC } from 'react';
import { Layout } from './Layout';
import CLOSE_ICON from '../assets/close.png';
import RED_CLOSE_ICON from '../assets/closeRed.png';

type Prop = {
  color?: 'white' | 'red'
}

const CloseIcon: FC<HTMLAttributes<HTMLDivElement> & Prop> = ({ style, color, ...others }) => (
  <Layout>
    {({ components: { Image } }) => (
      <Image src={color === 'red' ? RED_CLOSE_ICON : CLOSE_ICON}
        style={{ cursor: 'pointer', position: 'absolute', top: '40px', right: '40px', width: '40px', height: '40px', ...style }} {...others} />
    )}
  </Layout>
)
export default CloseIcon;