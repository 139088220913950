import { InferProps } from "prop-types";
import React, { Fragment } from "react";
import { FormattedHTMLMessage } from "react-intl";
import { GameModelInstance } from "../model/GameModel/";
import ReactKRPano, { ISnapshotData } from "./react-krpano";
import TakePictureButton from "./TakePictureButton";

const propTypes = {};

export type HydroplantVirtualTourProps = InferProps<typeof propTypes> & {
  game: GameModelInstance;
  onSnapshot: (data: ISnapshotData) => void;
};

type State = {
  snapshotting: boolean;
  warningMessageVisible: boolean;
};
export type HydroplantVirtualTourType = typeof HydroplantVirtualTour;

export default class HydroplantVirtualTour extends React.Component<
  HydroplantVirtualTourProps,
  State
> {
  private krpanoRef: React.RefObject<ReactKRPano>;

  static defaultProps = {
    onSnapshot: (image: string) => {},
  };

  constructor(props: HydroplantVirtualTourProps) {
    super(props);
    this.state = { snapshotting: false, warningMessageVisible: false };
    this.krpanoRef = React.createRef();
    this.handleTakeSnapshot = this.handleTakeSnapshot.bind(this);
    this.photosLimitReachedWarning = this.photosLimitReachedWarning.bind(this);
    this.manageKeyEvent = this.manageKeyEvent.bind(this);
  }

  handleTakeSnapshot() {
    this.setState({ snapshotting: true }, () => {
      this.krpanoRef.current &&
        this.krpanoRef.current.takeSnapshot("image/jpeg").then((data) => {
          setTimeout(() => this.setState({ snapshotting: false }), 500);
          if (this.krpanoRef.current) {
            this.props.onSnapshot(data);
          }
        });
    });
  }

  photosLimitReachedWarning() {
    this.setState({ warningMessageVisible: true });
  }

  manageKeyEvent(event: any) {
    switch (event.keyCode) {
      //Space
      case 32: {
        if (!this.state.snapshotting) {
          this.handleTakeSnapshot();
        }
        break;
      }
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.manageKeyEvent);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.manageKeyEvent);
  }

  render() {
    const { game } = this.props;

    const photosLimitReached =
      game.state.pictures.length === game.state.picturesLimit;

    return (
      <Fragment>
        {this.state.warningMessageVisible && (
          <div
            className={`warningMessageOverlay`}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(128 128 128 / 25%)",
              position: "absolute",
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              this.setState({ warningMessageVisible: false });
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "2rem",
                zIndex: 2,
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <h1
                style={{
                  color: "rgb(244, 75, 72)",
                }}
              >
                <FormattedHTMLMessage id="warningmessage:title" />
              </h1>
              <p
                style={{
                  fontSize: "1rem",
                }}
              >
                <FormattedHTMLMessage id="warningmessage:description" />
              </p>
              <button
                className="ui primary button"
                style={{
                  fontWeight: "normal",
                  marginTop: "1.5rem",
                }}
              >
                <FormattedHTMLMessage id="warningmessage:close" />
              </button>
            </div>
          </div>
        )}
        <ReactKRPano
          ref={this.krpanoRef}
          xml={this.props.game.state.tourXML}
          basepath={this.props.game.state.tourBasepath}
          scene={
            this.props.game.state.position && this.props.game.state.position.id
          }
          webglsetting={{ preserveDrawingBuffer: true }}
          style={{
            width: "100%",
            top: 0,
            bottom: "60px",
            position: "absolute",
          }}
        />
        <div
          style={{
            position: "fixed",
            bottom: "90px",
            width: 100,
            left: 0,
            transform: "translateX(calc(50vw - 50px))",
          }}
        >
          <TakePictureButton
            onClick={
              photosLimitReached === true
                ? this.photosLimitReachedWarning
                : this.handleTakeSnapshot
            }
            disabled={this.state.snapshotting}
            border={25}
          />
        </div>
        <div
          style={{
            pointerEvents: "none",
            width: "100vw",
            height: "100%",
            position: "absolute",
            top: 0,
            bottom: 0,
            backgroundColor: "white",
            opacity: `${this.state.snapshotting ? "1" : "0"}`,
            transition: "opacity .5s",
          }}
        ></div>
      </Fragment>
    );
  }
}
