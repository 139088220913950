import React, { FC } from 'react';
import { Layout } from './Layout';
import { FormattedHTMLMessage } from 'react-intl';
import { any, func, InferProps } from 'prop-types';

const propTypes = {
  trigger: any,
  header: any,
  content: any,
  confirmCallback: func.isRequired,
  discardCallback: func
}

type PropTypes = InferProps<typeof propTypes>;


const ConfirmModal: FC<PropTypes> = ({ trigger, header, content, confirmCallback, discardCallback }) => {

  return (
    <Layout>
      {({ components: { Modal, Button, Header } }) => (
        <Modal
          trigger={trigger}
          header={
            <Header style={{ fontSize: '32px', color: '#f44b48', fontWeight: 'normal', borderBottom: 0, padding: 0 }}>
              {header}
            </Header>
          }
          content={
            <div style={{ margin: "46px", fontSize: '18px', color: '#524e4e' }}>
              {content}
            </div>
          }
          actions={[
            <Button size="big" style={{ width: '200px', height: '60px', fontWeight: 'normal', color: 'white', backgroundColor: '#a3a3a3' }} key={"dismiss"}>
              <FormattedHTMLMessage id="modal:dismiss" />
            </Button>,
            <Button
              style={{ width: '200px', height: '60px', fontWeight: 'normal' }}
              size="big"
              key={"submit"}
              color="red"
              onClick={() => confirmCallback()}
            >
              <FormattedHTMLMessage id="modal:submit" />
            </Button>
          ]}
          size="small"
          style={{
            padding: "74px 60px",
            backgroundColor: "white",
            textAlign: "center",
            lineHeight: 1.72
          }}
        />
      )}
    </Layout>
  )
}

export default ConfirmModal;