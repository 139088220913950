import React, { FC, HTMLAttributes } from 'react';
import TRASH_ICON from '../assets/trash.svg';
import { Layout } from './Layout';

const TrashIcon: FC<HTMLAttributes<HTMLDivElement>> = ({ style, ...others }) => (
  <Layout >
    {({ components: { Image } }) => (
      <Image src={TRASH_ICON} style={{
        color: '#f44b48',
        width: '18px',
        height: '18px',
        cursor: 'pointer',
        ...style
      }} {...others} />
    )}
  </Layout>

)

export default TrashIcon;