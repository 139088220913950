import chunk from 'lodash/chunk';
import { InferProps } from 'prop-types';
import React from 'react';
import { Layout } from './Layout';
import LeaderboardItem from './LeaderboardItem';
import { LeaderboardInstance } from '../model/GameModel/Leaderboard';

type Props = InferProps<{ leaderboard: LeaderboardInstance }>

export default class Leaderboard extends React.Component<Props> {
  getColorByRank(rank: number | undefined | null) {
    switch (rank) {
      case 1: return '#f44b48';
      case 2:
      case 3: return '#62c2d0';
      default: return '#dbdbdb';
    }
  }
  getMarginTop(index: number) {
    switch (index % 3) {
      case 0: return '0';
      case 1: return '30px';
      default: return '60px';
    }
  }
  render() {
    return (
      <Layout>
        {({ components: { Grid } }) => (
          <Grid columns={3} >
            {
              this.props.leaderboard && this.props.leaderboard.positions && chunk(this.props.leaderboard.positions, 3)
                .map((columns, index) => (
                  <Grid.Row key={index}
                    className='LeaderBoardRow'
                    style={{
                      borderBottom: '2px dashed #dadada',
                      height: '157px',
                      margin: 'auto'
                    }}>

                    {
                      columns.map((position: any, index) => (
                        <Grid.Column key={index} style={{ marginTop: this.getMarginTop(index) }} textAlign='center'>
                          <LeaderboardItem key={index} item={{
                            rank: position.rank,
                            name: position.game.team.name,
                            score: position.score
                          }}
                            width={250}
                            height={80}
                            color={this.getColorByRank(position.rank)}
                          />
                        </Grid.Column>
                      ))}
                  </Grid.Row>
                ))


            }
          </Grid>
        )}
      </Layout>
    )
  }
}