import React, { FC, HTMLAttributes } from 'react';
import BOCCONI_LOGO from '../assets/Bocconi.png';
import { Layout } from './Layout';

const BocconiLogo: FC<HTMLAttributes<HTMLImageElement>> = ({ style }) => (
  <Layout>
    {({ components: { Image } }) => (
      <Image
        size="small"
        inline
        src={BOCCONI_LOGO}
        style={{ width: "164px", height: '37px', maxWidth: 'none', ...style }}
      />
    )}
  </Layout>
)
export default BocconiLogo;