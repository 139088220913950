import React, { Fragment } from "react";
import { FormattedHTMLMessage } from "react-intl";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import PLANTMAP_ICON from "../assets/plantMapIcon.png";
import Game from "../components/Game";
import HydroplantSidebar from "../components/HydroplantSidebar";
import HydroplantVirtualTour from "../components/HydroplantVirtualTour";
import { Layout } from "../components/Layout";
import Overlay from "../components/Overlay";
import RectIconButton from "../components/RectIconButton";

const RouteGameVirtualTour: React.FunctionComponent<
  RouteComponentProps<{ gameID: string }>
> = (props) => {
  const isMobileOrTablet: boolean =
    typeof window !== "undefined" &&
    window.matchMedia("(max-device-width: 1224px)").matches;

  return (
    <Layout>
      {({ components: { Icon, Image } }) => (
        <Fragment>
          <Game gameID={props.match.params.gameID}>
            {({ game }) => (
              <div
                style={{
                  position: "relative",
                  height: "calc(100vh - 100px)",
                  width: isMobileOrTablet ? "100vw" : "75vw",
                  margin: "auto",
                }}
              >
                <HydroplantVirtualTour
                  game={game}
                  onSnapshot={(data) => game.takeAPicture(data)}
                />
                <HydroplantSidebar
                  game={game}
                  visible={game.sidebar}
                  closeCallback={() => game.closeSidebar()}
                  selectCallback={(index: number) => {
                    game.closeSidebar();
                    game.showPicture(index);
                  }}
                />
                {game.sidebar && (
                  <Overlay
                    style={{
                      width: "100vw",
                      height: "100vh",
                      position: "fixed",
                    }}
                  />
                )}
              </div>
            )}
          </Game>

          <div
            style={{
              position: "absolute",
              bottom: "92px",
              left: "30px",
              zIndex: 100,
            }}
          >
            <RectIconButton>
              <Link
                to={`/participant/games/${props.match.params.gameID}/plant-map`}
              >
                <Image
                  src={PLANTMAP_ICON}
                  style={{
                    width: "100%",
                    height: "77px",
                    padding: "3px",
                    zIndex: 100,
                  }}
                />
                <FormattedHTMLMessage id="plantMap:text" />
              </Link>
            </RectIconButton>
          </div>
        </Fragment>
      )}
    </Layout>
  );
};

export default RouteGameVirtualTour;
