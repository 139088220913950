import { InferProps } from "prop-types";
import React from "react";
import { Layout, LayoutContextProps } from "./Layout";
import arrow from '../assets/grey-arrow.png'

const propTypes = {

};

export type DropdownProps = InferProps<typeof propTypes> &
  LayoutContextProps["Dropdown"];

const CustomDropdown: React.SFC<DropdownProps> = ({
  placeholder,
  options,
  onChange,
  className,
  style,
  value,
  disabled=false
}) => (
    <Layout>
      {({ components: { Dropdown } }) => (
        <Dropdown
          disabled={disabled}
          icon={<img className="icon" src={arrow} height={12} width={21} alt="" />}
          className={`CustomDropdown ${className}`}
          placeholder={placeholder}
          options={options}
          onChange={onChange}
          value={value}
          style={{
            backgroundColor: "#0d549b", color: 'white', width: "306px", padding: '11px 0 11px 18px', textAlign: 'left', fontSize: '20px', ...style
          }} />
      )}
    </Layout>
  );

export default CustomDropdown;
