import { observer } from "mobx-react";
import React, { Fragment, useState } from "react";
import { FormattedHTMLMessage } from 'react-intl';
import { ClusterInstance } from "../model/GameModel/Cluster";
import ActionButton from "./ActionButton";
import { Layout } from './Layout';
import PhotoGallery from "./PhotoGallery";
import TrashWhiteIcon from "./TrashWhiteIcon";
type Props = {
  cluster: ClusterInstance,
  onSelect: () => void,
  onUntag: (pictureID: string) => void
}

const GameClusterPictures: React.FC<Props> = ({ cluster, onSelect, onUntag }) => {
  const [state, setState] = useState({
    selected: 0
  })

  return (
    <Layout>
      {({ components: { Icon } }) => (

        <Fragment>
          <p className='GameClusterPicturesHeaderSection' onClick={onSelect} style={{ cursor: 'pointer', marginBottom: '0', position: 'absolute', top: '1.25rem', left: '1.25rem', zIndex: 100 }}>
            <Icon
              name="angle left"
              size="large"
              style={{ opacity: 0.3, }}
            />
            <span style={{ color: 'rgb(244, 75, 72)', fontSize: 18, fontWeight: 300, }}>{cluster.name}</span>
            <b style={{
              fontSize: 14,

            }}>
              <small style={
                {
                  verticalAlign: 'bottom',
                  marginLeft: '0.2rem'
                }
              }>({cluster.pictures.length})</small>
            </b>
          </p>
          <PhotoGallery
            images={cluster.pictures}
            selected={state.selected}
            onArrowChange={selected => setState({ selected })}
            actions={
              <ActionButton style={{
                borderRadius: '15.5px', width: '92px', fontSize: '12px', padding: '8px'
              }} onClick={() => onUntag(cluster.pictures[state.selected].id)}>
                <TrashWhiteIcon style={{ display: 'inline-block', marginRight: '5px', width: '14px', height: '14px', verticalAlign: 'middle' }} />
                <span style={{ verticalAlign: 'middle' }}><FormattedHTMLMessage id="button:remove" /></span>
              </ActionButton>
            } />
        </Fragment>
      )}
    </Layout>
  )
}

export default observer(GameClusterPictures);