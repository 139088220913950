import { types, Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree";

export const User = types.model('User')
  .props({
    id: types.string,
    name: types.string
  })

export interface UserInstance extends Instance<typeof User> { }
export interface UserSnapshotIn extends SnapshotIn<typeof User> { }
export interface UserSnapshotOut extends SnapshotOut<typeof User> { }