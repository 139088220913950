import range from 'lodash/range';
import React from 'react';

const Circle: React.FC<{ opacity: string, width: string, color: string }> = ({ children, opacity, width, color }) => (
  <div style={{ opacity, width, height: width, backgroundColor: color, borderRadius: '50%', position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}>{children}</div>
)

const Hotspot: React.FC<{ levels: number, color: string, width: number }> = ({ levels, color, width }) => {
  const widthUnit = width / levels;
  const opacityUnit = 1 / levels;
  return (
    <div style={{ position: 'relative', width: `${width}px`, height: `${width}px` }}>
      {
        range(levels).map((index: any) => (
          <Circle key={index} opacity={opacityUnit.toString()} width={`${widthUnit * (index + 1)}px`} color={color} />
        ))
      }
    </div>
  )
}

export default Hotspot;