import { types, Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree";

export const Leaderboard = types.model('Leaderboard')
  .props({
    id: types.string,
    positions: types.array(types.model({
      rank: types.number,
      score: types.number,
      game: types.model({
        id: types.string,
        team: types.model({
          id: types.string,
          name: types.string
        })
      })
    }))
  })

export interface LeaderboardInstance extends Instance<typeof Leaderboard> { }
export interface LeaderboardSnapshotIn extends SnapshotIn<typeof Leaderboard> { }
export interface LeaderboardSnapshotOut extends SnapshotOut<typeof Leaderboard> { }