import React, { FC } from "react";
import { Icon, Input } from "semantic-ui-react";

type Props = {
  value: string
  edit: boolean
  onSaveIconClick: (value: string) => void
  onEditIconClick: () => void
  onChange: (value: string) => void
} & React.HTMLAttributes<HTMLInputElement>

export const TextInput: FC<Props> = ({ edit, value, onSaveIconClick, onEditIconClick, onChange, style, ...others }) => (
  <Input
    style={{ width: "100%", textAlign: "center", ...style }}
    size="small"
    fluid
    labelPosition="right"
    transparent={!edit}
    value={value}
    disabled={!edit}
    label={() =>
      edit ? (
        <Icon name="save"
          size="small"
          onClick={() => {
            onSaveIconClick(value)
          }}
          style={{ cursor: 'pointer', fontSize: '1em' }} />
      ) : (
          <Icon name="edit"
            size="small"
            onClick={(el: any, ev: any) => {
              const parent = el.currentTarget.parentElement;
              onEditIconClick();
              setTimeout(() => parent.children[0].focus(), 200);
            }}
            style={{ cursor: 'pointer', fontSize: '1em' }} />
        )
    }
    onChange={(e, data) => onChange(data.value)}
    {...others}
  />
);