import React, { Component, FC } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import FACTORY_IMG from '../assets/factory.png';
import GRANDO_ICON from '../assets/grando.png';
import HYDRO_LOGO from '../assets/HydroLogo.png';
import BocconiAreaLogoComplete from '../components/BocconiAreaLogoComplete';
import { Layout } from '../components/Layout';

export type RouteHomeProps = RouteComponentProps<{}>;

const OpacityBox: FC = ({ children }) => (
  <div style={{ borderRadius: '11px', width: '354px', height: '146px', padding: '22px 51px 22px 57px', backgroundColor: 'rgba(255, 255, 255, 0.84)', fontWeight: 'bold', position: 'absolute', right: '37px', top: '297px' }}>
    {children}
  </div >
)

export default class RouteHome extends Component<RouteHomeProps> {

  render() {
    return (
      <Layout>
        {({ components: { Page, Grid, Image, Header, Button } }) => (


          <Page style={{ width: "100%", height: "100%" }}>
            <Page.Layer style={{ position: 'absolute', top: 0, bottom: 0, width: '100%' }}>
              <div style={{ backgroundImage: `url(${FACTORY_IMG})`, height: '100%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', position: 'relative', top: 0, backgroundPosition: 'bottom' }}>

                <OpacityBox>
                  <Header style={{
                    color: '#0D549B',
                    fontSize: '18px',
                    letterSpacing: 0,
                    lineHeight: '21px',
                    fontWeight: 500,
                    textAlign: 'right',
                  }}><FormattedHTMLMessage id='faculty-authors:label' /></Header>
                  <Grid style={{
                    marginTop: '12px',
                    paddingRight: '18px'
                  }}>
                    <Grid.Column width={11} verticalAlign='middle' style={{ color: 'var(--brownish-grey)', padding: 0, fontSize: '24px', fontWeight: 'bold', letterSpacing: 0, lineHeight: '28px', textAlign: 'right' }}>
                      <FormattedHTMLMessage id='alberto.grando:label' />
                    </Grid.Column>
                    <Grid.Column width={5} style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}>
                      <Image src={GRANDO_ICON} style={{ width: '68px', height: '68px', maxWidth: 'none', display: 'inline-block', backgroundSize: 'cover' }} />
                    </Grid.Column>
                  </Grid>
                </OpacityBox>
                <div className='headerTextContainerBox' style={{
                  position: 'absolute',
                  left: '195px',
                  top: '57px'
                }}>
                  <Image src={HYDRO_LOGO} style={{ display: 'inline-block', width: '8.125rem', marginRight: '2rem', marginTop: '.4rem' }} />
                  <div className='headerTextContainer' style={{
                    display: 'inline-block',
                    textAlign: "left",
                    verticalAlign: 'top',
                    color: 'white',
                    fontSize: '70px', letterSpacing: 0, lineHeight: '55px'
                  }}>
                    <FormattedHTMLMessage id={"app.splitted-title.1:label"} />
                    <span style={{ color: '#0d549b', fontWeight: 'bold' }}><FormattedHTMLMessage id={"app.splitted-title.2:label"} /></span><br />
                    <span style={{
                      color: 'white',
                      fontSize: '30px',
                      letterSpacing: 0,
                      lineHeight: '35px'
                    }}><FormattedHTMLMessage id={"app.splitted-subtitle:label"} /></span>
                  </div>
                </div>
                <div style={{ position: 'absolute', bottom: '24px', right: '37px' }}>
                  <BocconiAreaLogoComplete />
                </div>
              </div>
            </Page.Layer>
          </Page>
        )}
      </Layout>
    )
  }

}