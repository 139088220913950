import React, { HTMLAttributes } from 'react';
import { FormattedHTMLMessage } from 'react-intl';


const WelcomeTeamName: React.FC<HTMLAttributes<HTMLDivElement>> = ({ children, style }) => (
  <span style={{
    color: '#0d549b',
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    display: 'inline-block',
    backgroundColor: 'white',
    verticalAlign: 'top',
    padding: '14px 25px',
    border: '1px solid #0d549b',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
    borderTop: 0,
    ...style
  }}>
    <FormattedHTMLMessage id="welcome:team" /> <span style={{ textDecoration: 'underline' }}>{children}</span>
  </span>
)

export default WelcomeTeamName;